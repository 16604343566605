import {
  BannerData,
  BannerType,
  Filter,
  ListResult,
  PublishStatus,
} from 'app/models';

import Axios from '.';

/**
* * get Banner List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/banners)
+*/

export interface GetBannerListParams extends Filter<BannerData> {
  publishStatus?: PublishStatus;
  hasVerticalImage?: boolean;
  bannerType?: BannerType;
}

export const getBannerList = async (params: GetBannerListParams) => {
  const { data } = await Axios.get<ListResult<BannerData>>('/banners', {
    params,
  });
  return data;
};

/**
* * get One Banner (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/banners/-bannerId-)
+*/

export const getOneBanner = async (bannerId: string | number) => {
  const { data } = await Axios.get<BannerData>(`/banners/${bannerId}`);
  return data;
};

/**
* * create One Banner (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/banners)
+*/

export interface CreateOneBannerParams {
  images: string[];
  verticalImage?: string;
  title: string;
  selfLearnCourseId: number | string | null;
  courseId: number | string | null;
  publishedDate: string;
  type: BannerType;
}

export const createOneBanner = async (params: CreateOneBannerParams) => {
  const { data } = await Axios.post<BannerData>(`/banners`, params);
  return data;
};

/**
* * update One Banner (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/banners/-bannerId-)
+*/

// export interface GetInfoListParams extends Filter<BannerData> {}

export const updateOneBanner = async (
  bannerId: string | number,
  params: Partial<CreateOneBannerParams>,
) => {
  const { data } = await Axios.patch<BannerData>(
    `/banners/${bannerId}`,
    params,
  );
  return data;
};

/**
* * update All Banner (https://svgroup-app-dev.appicidea.com/doc/v2#put-/v1/banners/ordering)
+*/

export interface UpdateAllBannerOrderingParams {
  bannerId: number;
  ordering: number;
}

export const updateAllBanner = async (
  params: UpdateAllBannerOrderingParams,
) => {
  const { data } = await Axios.put<null>(`/banners/ordering`, params);
  return data;
};

/**
* * delete One Banner (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/companyActivities/-companyActivityId-)
+*/

export const deleteOneBanner = async (bannerId: string | number) => {
  const { data } = await Axios.delete<string>(`/banners/${bannerId}`);
  return data;
};
