import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Admin } from 'app/models';
import { logout } from 'app/services/AdminServices';
import { auth } from 'app/services/FirebaseService';
import { signOut } from 'firebase/auth';
import { AppThunk } from '.';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  profile: Admin | null;
  profileLoading: boolean;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
  profile: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    setProfile(state, action: PayloadAction<Admin | null>) {
      state.profile = action.payload;
    },
    setProfileLoading(state, action: PayloadAction<boolean>) {
      state.profileLoading = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { loginStart, loginSuccess, loginFail, setProfile } =
  authSlice.actions;
export default authSlice.reducer;

// dispatch actions

export const logoutAdmin =
  (history): AppThunk =>
  async dispatch => {
    try {
      await logout();
      await signOut(auth);
      dispatch(setProfile(null));
      history.replace('/login');
    } catch (err) {
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    }
  };
