export type PublishStatus = 'Published' | 'Unpublished';

export type ApplicationStatus = 'Pending' | 'Accepted' | 'Rejected';

export type FormType = 'create' | 'edit';

export interface ListResult<T> {
  count: number;
  rows: T[];
  hasNext?: boolean;
  page?: number;
  limit?: number;
  offset?: number;
}

export interface UserResult<T> {
  count: number;
  row: T[];
}

export interface Column {
  name: string;
  field: string;
  isImage?: boolean;
  isSecureImage?: boolean;
  isAvatar?: boolean;
  dateFormat?: string;
  buildingNumber?: boolean;
}

export interface Filter<T> {
  limit: number;
  offset?: number;
  page?: number;
  desc?: keyof T;
  asc?: keyof T;
}

export interface TimeStamp {
  createdAt: string;
  deletedAt?: null | string;
  updatedAt: string;
}

export interface OptionItems {
  value: string;
  label: string;
}

export interface GroupedOptionItems {
  key: OptionItems;
}

export interface BreadcrumbItemProps {
  title: string;
  path: string;
}

export interface LocalFile {
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  path?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
  delete?: boolean;
  preview?: null | undefined | any | string;
  formattedSize?: string;
}

export interface FileResponse {
  fileName: string;
  url: string;
  supportSizes: string[];
}

export interface CheckBoxOption {
  value: string;
  label: string;
  check: boolean;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly OptionItems[];
}

export interface ListColumn<Type> {
  text: string;
  dataField: keyof Type | 'img' | 'menu' | '';
  canSort?: boolean;
  formatter?: (item: Type) => JSX.Element;
}

export enum HTMLLink {
  'tel' = '電話',
  'link' = '網址',
  'email' = '電郵',
  'video' = '影片',
}

export type HTMLLinkType = keyof typeof HTMLLink;

export interface FileItem extends File {
  preview: string;
}

export interface PreviewFile {
  name: string;
  image: string;
  preview: string;
}
