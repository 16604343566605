import { APP_NAME } from 'app/config';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Form, Formik, FormikHelpers } from 'formik';
import { VideoField } from 'app/components/Form';
import { useCallback, useEffect, useState } from 'react';
import { changePreloader } from 'store/Layout';
import {
  getSelfLearnVideo,
  putSelfLearnVideo,
} from 'app/services/CourseService';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { uploadFile } from 'app/services/CommonService';
import { getImageURL } from 'app/helpers/CommonHelper';
import * as Sentry from '@sentry/react';

type FormItem = {
  video: any[];
};

const FORM_ITEM: FormItem = {
  video: [],
};

const Schema = Yup.object().shape({
  video: Yup.mixed().required('必填'),
});

export const SelfLearnVideoFormPage = () => {
  const BreadcrumbsOptions = [{ title: '自訂影片', path: '#' }];
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const [form, setForm] = useState<FormItem>(FORM_ITEM);
  const [Loading, setLoading] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    try {
      const VideoRes = await getSelfLearnVideo();
      if (VideoRes) {
        setForm({
          video: [
            {
              preview: getImageURL(VideoRes.video, 768),
              video: VideoRes.video,
              name: VideoRes.video.split('_').at(-1),
            },
          ],
        });
      }
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      dispatch(changePreloader(false));
    }
  }, [dispatch]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', values.video[0]);
      const VideoStringRes = await uploadFile(formData);
      await putSelfLearnVideo({
        video: VideoStringRes.fileName,
      });
      toast.success('上載成功。');
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>自訂影片 | {APP_NAME}</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs
            title="自訂影片"
            breadcrumbItems={BreadcrumbsOptions}
          ></Breadcrumbs>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>上載自訂影片</CardTitle>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ values, errors }) => (
                          <Form className="form-horizontal">
                            <div>
                              <div className="mb-3">
                                <VideoField multiple={false} name="video" />
                              </div>

                              <div className="mt-3 d-flex flex-row-reverse">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1"
                                  disabled={Loading}
                                >
                                  {Loading ? (
                                    <i className="bx bx-loader-circle bx-spin" />
                                  ) : (
                                    '提交'
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
