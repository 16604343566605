import { IMAGE_URL } from 'app/config';
import moment, { Moment } from 'moment';
import 'moment/locale/zh-tw';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const formatNumber = (num?: number | null): string => {
  if (num || num === 0) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '-';
};

export const getMonthRange = (
  date: Moment,
  currentPostion: 'start' | 'middle' | 'end' = 'middle',
  range: number = 3,
  // odd number
  DateFormat: string = 'YYYY-MM-DD',
) => {
  const temp = {
    startDate: '',
    endDate: '',
  };
  switch (currentPostion) {
    case 'start':
      temp.startDate = moment(date).startOf('month').format(DateFormat);
      temp.endDate = moment(date)
        .add(range - 1, 'month')
        .endOf('month')
        .format(DateFormat);
      break;
    case 'end':
      temp.startDate = moment(date)
        .subtract(range - 1, 'month')
        .startOf('month')
        .format(DateFormat);
      temp.endDate = moment(date).endOf('month').format(DateFormat);
      break;
    default:
      temp.startDate = moment(date)
        .subtract(Math.floor(range / 2), 'month')
        .startOf('month')
        .format(DateFormat);
      temp.endDate = moment(date)
        .add(Math.floor(range / 2), 'month')
        .endOf('month')
        .format(DateFormat);
  }
  return temp;
};

export const formatDisplayDate = (date: string | Date, format?: string) => {
  const now = moment();
  const diff = now.diff(date, 'days');

  if (diff < 7) {
    if (diff === 0) {
      return '今日';
    } else if (diff === 1) {
      return '昨天';
    } else {
      return moment(date).locale('zh-tw').format('MM月DD日 dddd');
    }
  } else {
    return moment(date).format('YYYY年MM月DD日');
  }
};

export const convertToDateTime = (time: string): Date => {
  const [hours, minutes, seconds = '00'] = time.split(':');
  const date = new Date('1/1/2023');
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  date.setSeconds(Number(seconds));
  return date;
};
