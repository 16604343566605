import { CourseData } from 'app/models';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';

interface Props {
  data: CourseData;
}

export const CourseInfoCard = (props: Props) => {
  const { data } = props;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="mb-2" sm={6}>
            <CardTitle className="mb-4 fs-4">課程資料</CardTitle>
          </Col>
          <Col sm={6}>
            <div className="d-flex flex-row-reverse mb-2">
              <Link to={`/courses/edit/${data.selfLearnCourseId}`}>
                <button className="btn btn-primary" aria-pressed="true">
                  {` ${'編輯'}`}
                </button>
              </Link>
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          <Table className="table-nowrap mb-0">
            <tbody>
              <tr>
                <th scope="row">課程標題 :</th>
                <td>
                  <div>{data?.title}</div>
                </td>
              </tr>
              <tr>
                <th scope="row">課程順序 {`(標籤)`} :</th>
                <td>
                  <div>{data?.courseOrderingName || ''}</div>
                </td>
              </tr>
              <tr>
                <th scope="row">發佈日期 :</th>
                <td>
                  <div>
                    {moment() > moment(data?.publishedDate) ? (
                      <Badge
                        pill
                        className={`badge-soft-success px-2 py-1 mt-1 font-size-12 rounded-pill`}
                        color="none"
                      >
                        已發佈
                      </Badge>
                    ) : (
                      <Badge
                        pill
                        className={`badge-soft-warning px-2 py-1 mt-1 font-size-12 rounded-pill`}
                        color="none"
                      >
                        尚未發佈
                      </Badge>
                    )}
                    <div className="text-dark">
                      {moment(data?.publishedDate).format('YYYY-MM-DD HH:mm')}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">完成課程可賺取積分 :</th>
                <td>
                  <div>
                    {data?.pointEarnAfterCompletion
                      ? data?.pointEarnAfterCompletion
                      : 0}{' '}
                    積分
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">課程總結 :</th>
                <td>
                  <div style={{ whiteSpace: 'normal' }}>
                    {data?.afterCompletionDescription
                      ? data?.afterCompletionDescription
                      : '-'}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">課程創建日期 :</th>
                <td>{moment(data?.createdAt).format('YYYY-MM-DD')}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};
