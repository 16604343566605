import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { APP_NAME } from 'app/config';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User, UserLog } from 'app/models';
import { toast } from 'react-toastify';
import { getUser, getUserLog } from 'app/services/UserService';
import { UserInfoCard } from 'app/components/User';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import * as Sentry from '@sentry/react';

interface UserQuery {
  userid?: string;
}

export const UserDetailPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const { userid } = useParams<UserQuery>();
  // const [loading, setLoading] = useState<boolean>(true);

  const [userData, setUserData] = useState<User>();
  const [userLog, setUserLog] = useState<UserLog>();

  const TITLE = `用戶資料`;

  const BreadcrumbsOptions = [
    { title: '用戶', path: '/users' },
    { title: '用戶列表', path: '/users' },
    { title: TITLE, path: '#' },
  ];

  const initPage = useCallback(async () => {
    dispatch(changePreloader(true));
    try {
      if (userid) {
        const res = await getUser(Number(userid));
        setUserData(res);
        try {
          const logRes = await getUserLog(userid);
          if (logRes.count) {
            setUserLog(logRes.rows[0]);
          }
        } catch (err) {
          // no log record found.
        }
      }
    } catch (err: any) {
      console.log(err);
      if (err.response?.status === 404) {
        toast.error('用戶不存在。');
      } else {
        toast.warning('請檢查你的網絡。');
        Sentry.captureException(err);
      }
    } finally {
      // setLoading(false);
      dispatch(changePreloader(false));
    }
  }, [userid, dispatch]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        {isPreloader ? null : (
          <Container fluid>
            <Breadcrumbs title="用戶" breadcrumbItems={BreadcrumbsOptions} />
            <Row>
              <Col lg="12">
                <>
                  <Row>
                    <Col md={12}>
                      {userData ? (
                        <UserInfoCard
                          data={userData}
                          refreshUser={initPage}
                          log={userLog}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
