/* eslint-disable jsx-a11y/role-supports-aria-props */
// MetisMenu
import MetisMenu from 'metismenujs';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
//i18n
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';
import { RootState } from 'store';

const SidebarContent = props => {
  // const { t } = useTranslation();
  const ref = useRef<any>();
  const location = useLocation();
  const { auth, layout } = useSelector((rootState: RootState) => rootState);
  const { profile } = auth;
  const { hasUnreadBotChat } = layout;

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {profile?.role === '超級管理員' ? (
              <>
                <li className="menu-title">功能列表</li>

                <li>
                  <NavLink
                    className="has-arrow waves-effect position-relative"
                    to="/botChat"
                  >
                    <i
                      className={`mdi mdi-robot ${
                        hasUnreadBotChat ? 'bx-tada' : ''
                      }`}
                    ></i>
                    <span>聊天機械人</span>
                    {hasUnreadBotChat ? (
                      <span className="badge border border-light rounded-circle bg-success p-1 ms-3">
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    ) : null}
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="/botChat" activeClassName="active mm-active">
                        聊天機械人列表
                        {hasUnreadBotChat ? (
                          <span className="badge border border-light rounded-circle bg-success p-1 ms-3">
                            <span className="visually-hidden">
                              unread messages
                            </span>
                          </span>
                        ) : null}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/botChat-QA"
                        activeClassName="active mm-active"
                      >
                        問答列表
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink className="has-arrow waves-effect" to="/banners">
                    <i className="mdi mdi-view-carousel-outline"></i>
                    <span>橫額</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="/banners" activeClassName="active mm-active">
                        橫額
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/banners-sort"
                        activeClassName="active mm-active"
                      >
                        橫額排序
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/popup-banner"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="mdi mdi-party-popper"></i>
                    <span>彈出海報</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink className="has-arrow waves-effect" to="/courses">
                    <i className="bx bx-book-bookmark"></i>
                    <span>自學課程</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="/courses" activeClassName="active mm-active">
                        課程
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/course-tests"
                        activeClassName="active mm-active"
                      >
                        課程測試
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/course-submissions"
                        activeClassName="active mm-active"
                      >
                        已完成的課程
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/course-test-submissions"
                        activeClassName="active mm-active"
                      >
                        已提交的課程測試
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/self-learn-video"
                        activeClassName="active mm-active"
                      >
                        自訂影片
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink className="has-arrow waves-effect" to="/events">
                    <i className="mdi mdi-calendar-month-outline"></i>
                    <span>活動</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="/events" activeClassName="active mm-active">
                        活動
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/event-categories"
                        activeClassName="active mm-active"
                      >
                        活動類別
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/event-applications"
                        activeClassName="active mm-active"
                      >
                        活動申請
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink className="has-arrow waves-effect" to="/resources">
                    <i className="mdi mdi-archive-outline"></i>
                    <span>男士資訊</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink
                        to="/resources"
                        activeClassName="active mm-active"
                      >
                        男士資訊
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/resource-categories"
                        activeClassName="active mm-active"
                      >
                        男士資訊類別
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    className="has-arrow waves-effect"
                    to="/forum/topics"
                  >
                    <i className="mdi mdi-account-multiple-outline"></i>
                    <span>男士討論區</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink
                        to="/forum/topics"
                        activeClassName="active mm-active"
                      >
                        男士討論區話題
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/forum/posts"
                        activeClassName="active mm-active"
                      >
                        男士討論區帖子
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/forum/reports"
                        activeClassName="active mm-active"
                      >
                        男士討論區舉報
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/forum/sensitive-keywords"
                        activeClassName="active mm-active"
                      >
                        男士討論區敏感詞
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink className="has-arrow waves-effect" to="/phq">
                    <i className=" mdi mdi-form-select"></i>
                    <span>心靈健康評估</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="/phq" activeClassName="active mm-active">
                        心靈健康評估
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/phq-submissions"
                        activeClassName="active mm-active"
                      >
                        已提交的評估
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    className="has-arrow waves-effect"
                    to="/consultations"
                  >
                    <i className="mdi mdi-comment-account-outline"></i>
                    <span>社工線上輔導</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink
                        to="/consultations"
                        activeClassName="active mm-active"
                      >
                        線上輔導時段
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/consultation-holidays"
                        activeClassName="active mm-active"
                      >
                        輔導休息時段
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/consultation-calendar"
                        activeClassName="active mm-active"
                      >
                        輔導日程表
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/consultation-applications"
                        activeClassName="active mm-active"
                      >
                        線上輔導申請
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/emergency-contacts"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="bx bx-phone-call"></i>
                    <span>緊急求助</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/notifications"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="bx bx-bell"></i>
                    <span>通知</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/daily-quotes"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="bx bx-purchase-tag-alt"></i>
                    <span>每日金句</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink className="has-arrow waves-effect" to="/group-chats">
                    <i className="bx bx-chat"></i>
                    <span>男士聊天室</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink
                        to="/group-chats"
                        activeClassName="active mm-active"
                      >
                        男士聊天室列表
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/group-chats-reports"
                        activeClassName="active mm-active"
                      >
                        男士聊天室舉報列表
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/take-down"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="mdi mdi-archive-arrow-down"></i>
                    <span>移除申請</span>
                  </NavLink>
                </li>

                <li className="menu-title">用戶</li>

                <li>
                  <NavLink
                    to="/admins"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="bx bx-user-circle"></i>
                    <span>管理員</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/users"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="bx bx-user"></i>
                    <span>用戶</span>
                  </NavLink>
                </li>
              </>
            ) : null}
            {profile?.role === '職員' ? (
              <>
                <li>
                  <NavLink
                    to="/group-chats"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="bx bx-chat"></i>
                    <span>男士聊天室列表</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/consultation-applications"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="mdi mdi-comment-account-outline"></i>
                    <span>線上輔導申請</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/botChat"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <i className="mdi mdi-robot"></i>
                    <span>聊天機械人列表</span>
                  </NavLink>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
