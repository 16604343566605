import CourseSessionColumns from './CourseSessionColumns';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import TableCount from 'app/components/Common/TableCount';
import { CourseSessionOrder, CustomCourseSessionData } from 'app/models';
import {
  CourseForm,
  CourseFormType,
} from 'app/pages/CoursePage/CourseDetailPage';
import { useCallback, useEffect, useState } from 'react';
import {
  getCourseSessionListByCourse,
  updateCourseSessionOrder,
} from 'app/services/CourseService';
import { toast } from 'react-toastify';
import { SortableTable } from '../Table';
import * as Sentry from '@sentry/react';

interface Props {
  courseId: number;
  onDialogButtonClick: (
    action: CourseFormType,
    form: CourseForm,
    id?: number,
  ) => void;
  onDeleteButtonClick: (value: number) => void;
}

export const CourseSessionListCard = (props: Props) => {
  const { courseId, onDeleteButtonClick, onDialogButtonClick } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const [count, setCount] = useState<number>(0);
  const [sessionList, setSessionList] = useState<CustomCourseSessionData[]>([]);
  const [originalSessionList, setOriginalSessionList] = useState<
    CustomCourseSessionData[]
  >([]);

  const getList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getCourseSessionListByCourse({
        limit: 99,
        offset: 0,
        asc: 'ordering',
        selfLearnCourseId: courseId,
      });
      setCount(res.count);
      const tempRows = res.rows.map(session => ({
        ...session,
        id: session.selfLearnSessionId.toString(),
      }));
      setSessionList(tempRows);
      setOriginalSessionList(tempRows);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }, [courseId]);

  useEffect(() => {
    getList();
  }, [getList, courseId]);

  let isPositionUpdated = false;
  for (let i = 0; i < originalSessionList.length; i++) {
    if (originalSessionList?.[i]?.id !== sessionList?.[i]?.id) {
      isPositionUpdated = true;
      break;
    }
  }

  const saveOnPress = async () => {
    setLoading(true);
    try {
      const tempList: CourseSessionOrder[] = sessionList.map(
        (session, index) => {
          return {
            selfLearnSessionId: session.selfLearnSessionId,
            ordering: index + 1,
          };
        },
      );
      await updateCourseSessionOrder(courseId, tempList);
      setOriginalSessionList(sessionList);
      toast.success('成功更新順序');
    } catch (err) {
      console.log(err);
      toast.error('更新順序失敗，請重試');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="mb-2" md={6}>
            <CardTitle className="mb-4 fs-4">課程單元列表</CardTitle>
          </Col>
          <Col md={6}>
            <div className="d-flex flex-row-reverse mb-2">
              <button
                className="btn btn-success ms-3"
                disabled={loading || !isPositionUpdated}
                onClick={() => saveOnPress()}
              >
                {loading ? (
                  <i className="bx bx-loader-circle bx-spin" />
                ) : (
                  <i className="mdi mdi-content-save-outline" />
                )}

                {` ${'儲存順序'}`}
              </button>
              <button
                onClick={() => onDialogButtonClick('create', 'session')}
                className="btn btn-primary"
                aria-pressed="true"
              >
                <i className="mdi mdi-plus" />
                {` ${'新增課程單元'}`}
              </button>
            </div>
            <div className="d-flex flex-row-reverse mb-2">
              <TableCount count={count} title="課程單元總數" />
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          <SortableTable
            data={sessionList || []}
            columns={CourseSessionColumns(
              onDeleteButtonClick,
              onDialogButtonClick,
            )}
            moveRows={(data: CustomCourseSessionData[]) => {
              setSessionList(
                data.map((item, index) => ({
                  ...item,
                  order: index,
                })),
              );
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
};
