import {
  Admin,
  AdminLoginPayload,
  AdminLoginResponse,
  AdminPara,
  AdminProfile,
  Filter,
  ListResult,
} from '../models';
import Axios from './';

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<AdminLoginResponse>(
    '/admins/passwordAuth/SignIn',
    para,
  );
  return data;
};

export interface GetAdminListParams extends Filter<Admin> {}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admins', {
    params,
  });
  return data;
};

export const createNewAdmin = async (para: AdminPara) => {
  const { data } = await Axios.post<Admin>('/admins', para);
  return data;
};

export const getSingleAdmin = async (adminId: string) => {
  const { data } = await Axios.get<Admin>(`/admins/${adminId}`);
  return data;
};

export interface UpdateAdminParams {
  displayName: string;
  avatar?: string;
}

export const updateAdmin = async (adminId: number, para: UpdateAdminParams) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, para);
  return data;
};

export const deleteAdmin = async (adminId: number) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<Admin>('/admins/me/profile');
  return data;
};

export const updateProfile = async (param: AdminProfile) => {
  const { data } = await Axios.patch<Admin>('/admins/me/profile', param);
  return data;
};

export const resetAdminPassword = async (
  adminId: number,
  para: { newPassword?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admins/${adminId}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/admins/me/logout');
  return data;
};
