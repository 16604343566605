import { memo, useEffect } from 'react';

interface props {
  isValid: boolean;
  submitCount: number;
  isValidating: boolean;
  isSubmitting: boolean;
}

export const ScrollToError = memo(
  ({ isValid, submitCount, isValidating, isSubmitting }: props) => {
    useEffect(() => {
      if (!isValid && !isValidating && submitCount !== 0 && !isSubmitting) {
        // get elements
        let errEle = document.querySelector('.has-danger');
        if (errEle) {
          errEle?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          // setInterval to get elements
          const tempInterval = setInterval(() => {
            if (!errEle) {
              errEle = document.querySelector('.has-danger');
            } else {
              errEle?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              clearInterval(tempInterval);
            }
          }, 300);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount, isValidating, isValid, isSubmitting]);
    return null;
  },
);
