/**
 *
 * Asynchronously loads the component for GroupChatListPage
 *
 */
import { lazyLoad } from 'utils/loadable';
export const GroupChatReportListPage = lazyLoad(
  () => import('./index'),
  module => module.GroupChatReportListPage,
);
