import { getImageURL } from 'app/helpers/CommonHelper';
import {
  ColumnDefinitionType,
  CourseSessionData,
  CustomCourseSessionData,
} from 'app/models';
import React from 'react';
import {
  CourseForm,
  CourseFormType,
} from '../../pages/CoursePage/CourseDetailPage';
import Tooltip from 'rc-tooltip';

const CourseSessionColumns: (
  onDeleteButtonClick: (value: number) => void,
  onDialogButtonClick: (
    action: CourseFormType,
    form: CourseForm,
    id?: number,
  ) => void,
) => ColumnDefinitionType<
  CustomCourseSessionData,
  keyof CustomCourseSessionData
>[] = (onDeleteButtonClick, onDialogButtonClick) => [
  {
    key: 'coverImage',
    header: '',
    formatter: (session: CourseSessionData) => (
      <>
        {!session.coverImage ? (
          <div className="avatar-md">
            <span className="avatar-sessionName rounded-circle bg-info">
              {session.sessionName ? (
                session.sessionName.charAt(0)
              ) : (
                <i className="bx bx-user" />
              )}
            </span>
          </div>
        ) : (
          <div>
            <img
              className="rounded-circle avatar-md"
              src={getImageURL(session.coverImage, 320)}
              alt={session.sessionName}
            />
          </div>
        )}
      </>
    ),
  },
  // {
  //   header:  '單元編號',
  //   key:  'sessionName',
  //   formatter: (session: CourseSessionData) => (
  //     <>
  //       <h5 className="font-size-14 mb-1">
  //         <div className="text-dark">{session.selfLearnSessionId}</div>
  //       </h5>
  //     </>
  //   ),
  // },
  // {
  //   header:  '課程單元順序',
  //   key:  'ordering',
  //   formatter: (session: CourseSessionData) => (
  //     <>
  //       <h5 className="font-size-14 mb-1">
  //         <div className="text-dark">
  //           {(session.ordering ? session.ordering : 0) + 1}
  //         </div>
  //       </h5>
  //     </>
  //   ),
  // },
  {
    header: '單元標題',
    key: 'sessionName',
    formatter: (session: CourseSessionData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{session.sessionName}</div>
        </h5>
      </>
    ),
  },
  {
    header: '單元問題',
    key: 'sessionName',
    formatter: (session: CourseSessionData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {session.question ? session.question : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    key: 'id',
    isDummyField: true,
    header: '',
    formatter: (session: CourseSessionData) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {/* <li className="list-inline-item px-2">
          <button
            className="session-list-button custom-btn"
            onClick={() =>
              onDialogButtonClick('view', 'session', session.selfLearnSessionId)
            }
            data-for={'view' + session.selfLearnSessionId}
            data-tip
          >
            <i className="bx bx-edit-alt" />
            <ReactTooltip
              effect="solid"
              id={'edit' + session.selfLearnCourseId}
            >
              查看課程單元
            </ReactTooltip>
          </button>
        </li> */}
        <li className="list-inline-item px-2">
          <Tooltip
            placement="top"
            overlayStyle={{ padding: '5px 0 5px 0' }}
            overlay={<div className="px-3 py-1 fs-6">編輯課程單元</div>}
            overlayInnerStyle={{ borderRadius: '4px' }}
          >
            <button
              className="session-list-button custom-btn"
              onClick={() =>
                onDialogButtonClick(
                  'edit',
                  'session',
                  session.selfLearnSessionId,
                )
              }
              data-for={'edit' + session.selfLearnSessionId}
              data-tip
            >
              <i className="bx bx-edit-alt" />
            </button>
          </Tooltip>
        </li>
        <li className="list-inline-item px-2">
          <Tooltip
            placement="top"
            overlayStyle={{ padding: '5px 0 5px 0' }}
            overlay={<div className="px-3 py-1 fs-6">刪除課程單元</div>}
            overlayInnerStyle={{ borderRadius: '4px' }}
          >
            <button
              className="notification-list-button custom-btn"
              onClick={() => onDeleteButtonClick(session.selfLearnSessionId)}
              data-for={'delete' + session.selfLearnSessionId}
              data-tip
            >
              <i className="mdi mdi-delete-outline" />
            </button>
          </Tooltip>
        </li>
      </ul>
    ),
  },
];

export default CourseSessionColumns;
