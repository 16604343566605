import { OptionItems } from 'app/models';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Label } from 'reactstrap';

interface Props {
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (e) => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
}

const SelectFilter = ({
  label,
  placeholder,
  options,
  onChange,
  isClearable,
  disabled,
  menuPlacement,
  name,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState<OptionItems | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    // eslint-disable-next-line
    const index = _.findIndex(options, option => option.value == key);
    if (index > -1) {
      setValue(options[index]);
    } else {
      setValue(null);
    }
  }, [location.search, name, options]);
  return (
    <div className="mb-2 form-group">
      {label ? <Label>{label}</Label> : null}
      <Select
        defaultValue={value}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        value={value}
        onChange={e => {
          const params = new URLSearchParams(location.search);
          if (onChange) {
            onChange(e);
          } else {
            if (e && e.value) {
              params.set(name, e.value);
            } else {
              params.delete(name);
            }
            params.set('page', '1');
            history.push({ search: params.toString() });
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
      />
    </div>
  );
};

export default SelectFilter;
