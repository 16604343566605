import { VIDEO_SIZE } from 'app/config';
import { formatBytes } from 'app/helpers/FormHelper';
import { LocalFile } from 'app/models';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import Dropzone from 'react-dropzone';
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Label, Row } from 'reactstrap';

import './form.scss';

interface OtherProps {
  label?: string;
  multiple?: boolean;
}

// const VIDEO_SIZE = undefined;

const VideoField = ({
  label,
  multiple,
  ...props
}: OtherProps & FieldHookConfig<LocalFile[] | null>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  const onDelete = (original, index) => {
    const temp = [...original];
    temp.splice(index, 1);
    if (temp.length > 0) {
      helper.setValue(temp);
    } else {
      helper.setValue(null);
    }
  };

  const onDrop = files => {
    let tmpFiles: LocalFile[] = [];
    if (field.value) {
      tmpFiles = [...field.value];
    }
    files.forEach(file => {
      if (file.size > VIDEO_SIZE) {
        toast.warning('檔案不可大於100MB');
      }
      if (file.type === 'video/mp4') {
        file['preview'] = URL.createObjectURL(file);
        file['formattedSize'] = formatBytes(file.size);
        if (multiple) {
          tmpFiles.push(file);
        } else {
          tmpFiles = [file];
        }
        helper.setValue(tmpFiles);
      } else {
        toast.warning('只接受mp4影片檔案');
      }
    });
  };

  const onDownload = (url: string) => {
    window.location.href = url;
  };
  return (
    <div className={clsx(' mb-4', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <Dropzone
        multiple={multiple ? true : false}
        onDrop={acceptedFiles => {
          onDrop(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>點擊或拉動影片檔案到此處</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div
        className={`dropzone-previews ${field.value ? 'mt-3' : ''}`}
        id="file-previews"
      >
        {field.value
          ? field.value.map((file, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + '-file'}
                >
                  <div className="p-2 text-info">
                    <Row className="align-items-start">
                      <Col className="col-auto">
                        <span className="font-size-24 text-dark px-2 py-1">
                          <i className="mdi mdi-file-video-outline"></i>
                        </span>
                      </Col>
                      <Col>
                        <Link to="#" className="text-dark font-weight-bold">
                          {file.name}
                        </Link>
                        <p className="mb-0 text-dark">
                          <strong>{file.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        {!file.size ? (
                          <Button
                            className="me-2"
                            color="warning"
                            onClick={() => onDownload(file.preview)}
                          >
                            <i className="bx bx-cloud-download"></i>
                          </Button>
                        ) : null}
                        <Button
                          color="danger"
                          onClick={() => onDelete(field.value, i)}
                        >
                          <i className="bx bx-trash-alt" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })
          : null}
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default VideoField;
