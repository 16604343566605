import React from 'react';

const Breadcrumb = ({
  count,
  title,
  className,
}: {
  count: number;
  title: string;
  className?: string;
}) => {
  return (
    <div
      className={`d-flex flex-row align-items-center justify-content-center ${className}`}
    >
      <div className="text-dark font-weight-bold" style={{ fontWeight: 700 }}>
        {title}
      </div>
      <div className="font-size-24 text-muted ms-2">{count}</div>
    </div>
  );
};

export default Breadcrumb;
