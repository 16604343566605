import {
  Event,
  EventData,
  Filter,
  ListResult,
  PublishStatus,
  EventCategory,
  EventApplicationData,
  EventApplicationStatus,
  EventApplication,
} from 'app/models';
import Axios from './index';

export interface GetEventListParams extends Filter<Event> {
  publishStatus?: PublishStatus;
  title?: string;
  categoryName?: string;
}

export const getEventList = async (params: GetEventListParams) => {
  const { data } = await Axios.get<ListResult<EventData>>('/courses', {
    params,
  });
  return data;
};

export const getEventCategoryList = async () => {
  const { data } = await Axios.get<EventCategory[]>('/courses/category');
  return data;
};

export const getEventData = async (eventId: number) => {
  const { data } = await Axios.get<EventData>(`/courses/${eventId}`);
  return data;
};

export const createNewEvent = async (param: Event) => {
  const { data } = await Axios.post<EventData>('/courses', param);
  return data;
};

export const deleteEvent = async (eventId: number) => {
  const { data } = await Axios.delete(`/courses/${eventId}`);
  return data;
};

export const updateEvent = async (eventId: number, param: Event) => {
  const { data } = await Axios.patch<EventData>(`/courses/${eventId}`, param);
  return data;
};

interface EventCategoryOrder {
  categoryName: string;
  ordering: number;
}

export const updateAllEventOrder = async (param: EventCategoryOrder[]) => {
  const { data } = await Axios.put<null>('/courses/category/ordering', param);
  return data;
};

// Event Application

export interface GetEventApplicationListParams
  extends Filter<EventApplicationData> {
  userId?: number;
  status?: EventApplicationStatus;
  startDate?: string;
  endDate?: string;
  courseId?: number;
}

export const getEventApplicationList = async (
  params: GetEventApplicationListParams,
) => {
  const { data } = await Axios.get<ListResult<EventApplicationData>>(
    '/courses/applications',
    {
      params,
    },
  );
  return data;
};

export const getEventApplicationData = async (courseApplicationId: number) => {
  const { data } = await Axios.get<EventApplicationData>(
    `/courses/applications/${courseApplicationId}`,
  );
  return data;
};

export const deleteEventApplication = async (courseApplicationId: number) => {
  const { data } = await Axios.delete(
    `/courses/applications/${courseApplicationId}`,
  );
  return data;
};

export const updateEventApplication = async (
  courseApplicationId: number,
  param: Partial<EventApplication>,
) => {
  const { data } = await Axios.patch<EventApplicationData>(
    `/courses/applications/${courseApplicationId}`,
    param,
  );
  return data;
};
