import { Link } from 'react-router-dom';
import { AvatarProps, UserAvatar } from './UserAvatar';
import { User } from 'app/models';
import 'app/assets/scss/components/user/UserRow.scss';

interface UserRowProps extends AvatarProps {
  user: User | null;
}

export const UserRow = ({ user, size, imageSize }: UserRowProps) => {
  // const { userId, displayName, avatar } = user;
  // if (!userId) {
  //   return (
  //     <div className="w-100 bg-white mb-3">
  //       <UserAvatar
  //         avatar={avatar}
  //         alt={displayName || ''}
  //         size={size}
  //         containerClass="me-2"
  //         imageSize={imageSize}
  //       />
  //       <h5 className="text-black mb-0">{displayName}</h5>
  //     </div>
  //   );
  // }

  return (
    <Link to={`/users/view/${user?.userId}`} target="_blank">
      <div className="w-100 bg-white user-row-container">
        <UserAvatar
          avatar={user?.avatar}
          alt={user?.displayName || ''}
          size={size}
          containerClass="me-2"
          imageSize={imageSize}
        />
        <h5 className="text-primary mb-0">{user?.displayName}</h5>
      </div>
    </Link>
  );
};
