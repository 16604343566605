import { getImageURL } from 'app/helpers/CommonHelper';

export type AvatarSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface AvatarProps {
  avatar?: string | null;
  size?: AvatarSizeType;
  containerClass?: string;
  alt?: string;
  imageSize?: number | 'original';
  iconsClass?: string;
}

export const UserAvatar = ({
  avatar,
  size = 'md',
  containerClass,
  alt,
  imageSize,
  iconsClass,
}: AvatarProps) => {
  if (!avatar) {
    return (
      <div className={`avatar-${size} ${containerClass}`}>
        <span className="avatar-title rounded-circle bg-info">
          {alt ? (
            alt.charAt(0)
          ) : (
            <i
              className={
                iconsClass ? iconsClass : 'mdi mdi-account-outline font-size-24'
              }
            />
          )}
        </span>
      </div>
    );
  }
  return (
    <img
      className={`rounded-circle avatar-${size} ${containerClass}`}
      src={getImageURL(avatar, imageSize || 320)}
      alt={alt}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.outerHTML = '<div>失效圖像</div>';
      }}
    />
  );
};
