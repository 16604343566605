import {
  EventApplicationData,
  EventApplicationStatus,
  OptionItems,
} from 'app/models';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'reactstrap';
import { MultipleSelectField, InputField, SelectField } from '../Form';
import { Formik, FormikProps } from 'formik';
import { EVENT_APPLICATION_OPTIONS, INFO_PATH_OPTIONS } from 'app/const';
import { updateEventApplication } from 'app/services/EventService';
import * as Sentry from '@sentry/react';

interface Props {
  data: EventApplicationData;
  afterUpdate: () => void;
}

const Schema = Yup.object().shape({
  email: Yup.string().email('電子郵件格式錯誤').nullable().required('必填'),
  contactPhone: Yup.string()
    .nullable()
    .matches(/^[2-9]\d{7}$/g, '香港電話號碼格式錯誤')
    .required('必填'),
  howYouHearFromUs: Yup.array().min(1, '必填'),
  status: Yup.string().required('必填'),
});

const FORM_ITEM = {
  email: '',
  contactPhone: '',
  howYouHearFromUs: [],
  status: 'Pending' as EventApplicationStatus,
};

interface FormItem {
  email: string;
  contactPhone: string;
  howYouHearFromUs: OptionItems[];
  status?: EventApplicationStatus;
}

export const EventApplicationListForm = (props: Props) => {
  const { data, afterUpdate } = props;
  const [initLoad, setInitLoad] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);
  const formRef = useRef<FormikProps<FormItem>>(null);

  const initForm = useCallback(async () => {
    try {
      if (data) {
        setForm({
          status: data.status,
          contactPhone: data.contactPhone,
          email: data.email,
          howYouHearFromUs: data.howYouHearFromUs.map(val => ({
            label: val,
            value: val,
          })),
        });
        setInitLoad(true);
      } else {
        setInitLoad(true);
      }
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateApplication = async (values: FormItem) => {
    try {
      const params = await formatData(values);
      await updateEventApplication(data.courseApplicationId, params);
      toast.success('編輯活動申請成功。');
      afterUpdate();
    } catch (err) {
      toast.warning('編輯活動申請失敗，請重試。');
      Sentry.captureException(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formatData = async (values: FormItem) => {
    const params = {
      ...values,
      courseId: data.courseId,
      userId: data.userId,
      howYouHearFromUs: values.howYouHearFromUs.map(val => val.value),
    };
    if (values.status === data.status) {
      delete params.status;
    }
    return params;
  };

  const onSubmit = async (values: FormItem) => {
    setLoading(true);
    updateApplication(values);
  };

  useEffect(() => {
    initForm();
  }, [initForm]);

  return (
    <>
      {initLoad ? (
        <div className="p-2">
          <Formik
            initialValues={form}
            validationSchema={Schema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ values }) => (
              <Form className="form-horizontal">
                <Row className="mb-3">
                  <Col md={12}>
                    <SelectField
                      name="status"
                      label="申請狀態"
                      placeholder="申請狀態"
                      options={EVENT_APPLICATION_OPTIONS}
                    />
                  </Col>
                  <Col md={12}>
                    <InputField
                      name="email"
                      label="電子郵件"
                      placeholder="電子郵件"
                    />
                  </Col>
                  <Col md={12}>
                    <InputField
                      name="contactPhone"
                      label="聯絡電話"
                      placeholder="聯絡電話"
                    />
                  </Col>
                  <Col md={12} className="mb-4">
                    <MultipleSelectField
                      name="howYouHearFromUs"
                      label="如何知道我們"
                      placeholder="如何知道我們"
                      options={INFO_PATH_OPTIONS}
                    />
                  </Col>
                </Row>
                <div className="mt-3 d-flex flex-row-reverse">
                  <Button
                    type="submit"
                    color="primary"
                    className="ms-1"
                    disabled={loading}
                    onClick={e => {
                      e.preventDefault();
                      formRef.current?.handleSubmit();
                    }}
                  >
                    {loading ? (
                      <i className="bx bx-loader-circle bx-spin" />
                    ) : (
                      '提交'
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
};
