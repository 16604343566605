import {
  Course,
  CourseData,
  CourseSession,
  CourseSessionData,
  CourseSessionOrder,
  CourseSubmission,
  CourseSubmissionData,
  Filter,
  ListResult,
  PublishStatus,
  SelfLearnVideo,
  Test,
  TestData,
  TestSubmissionData,
} from 'app/models';
import Axios from './index';

//
// Course Submission
//

export interface GetCourseSubmissionListParams
  extends Filter<CourseSubmissionData> {
  selfLearnCourseId?: number;
  userId?: number;
}

export const getCourseSubmissionList = async (
  params: GetCourseSubmissionListParams,
) => {
  const { data } = await Axios.get<ListResult<CourseSubmissionData>>(
    '/selfLearns/submission',
    {
      params,
    },
  );
  return data;
};

export const getCourseSubmissionData = async (
  selfLearnSubmissionId: number,
) => {
  const { data } = await Axios.get<CourseSubmissionData>(
    `/selfLearns/submission/${selfLearnSubmissionId}`,
  );
  return data;
};

export const updateCourseSubmission = async (
  selfLearnSubmissionId: number,
  param: CourseSubmission,
) => {
  const { data } = await Axios.patch<CourseSubmissionData>(
    `/selfLearns/submission/${selfLearnSubmissionId}`,
    param,
  );
  return data;
};

//
// Course
//

export interface GetCourseListParams extends Filter<CourseData> {
  publishStatus?: PublishStatus;
  title?: string;
}

export const getCourseList = async (params: GetCourseListParams) => {
  const { data } = await Axios.get<ListResult<CourseData>>('/selfLearns', {
    params,
  });
  return data;
};

export const getCourseData = async (selfLearnCourseId: number) => {
  const { data } = await Axios.get<CourseData>(
    `/selfLearns/${selfLearnCourseId}`,
  );
  return data;
};

export const createCourse = async (param: Course) => {
  const { data } = await Axios.post<CourseData>('/selfLearns', param);
  return data;
};

export const deleteCourse = async (selfLearnCourseId: number) => {
  const { data } = await Axios.delete(`/selfLearns/${selfLearnCourseId}`);
  return data;
};

export const updateCourse = async (
  selfLearnCourseId: number,
  param: Course,
) => {
  const { data } = await Axios.patch<CourseData>(
    `/selfLearns/${selfLearnCourseId}`,
    param,
  );
  return data;
};

export const updateCourseOrder = async (
  selfLearnCourseId: number,
  ordering: number,
) => {
  const { data } = await Axios.patch<CourseData>(
    `/selfLearns/${selfLearnCourseId}`,
    {
      ordering: ordering,
    },
  );
  return data;
};

//
// Course Session
//

export interface GetCourseSessionListParams extends Filter<CourseSessionData> {
  selfLearnCourseId?: number;
}

export const getCourseSessionList = async (
  params: GetCourseSessionListParams,
) => {
  const { data } = await Axios.get<ListResult<CourseSessionData>>(
    `/selfLearns/sessions`,
    {
      params,
    },
  );
  return data;
};

export const getCourseSessionListByCourse = async (
  params: GetCourseSessionListParams,
) => {
  const { data } = await Axios.get<ListResult<CourseSessionData>>(
    `/selfLearns/${params.selfLearnCourseId}/sessions`,
    {
      params,
    },
  );
  return data;
};

export const getCourseSessionData = async (selfLearnSessionId: number) => {
  const { data } = await Axios.get<CourseSessionData>(
    `/selfLearns/sessions/${selfLearnSessionId}`,
  );
  return data;
};

export const createCourseSession = async (param: CourseSession) => {
  const { data } = await Axios.post<CourseSessionData>(
    '/selfLearns/sessions',
    param,
  );
  return data;
};

export const deleteCourseSession = async (selfLearnSessionId: number) => {
  const { data } = await Axios.delete(
    `/selfLearns/sessions/${selfLearnSessionId}`,
  );
  return data;
};

export const updateCourseSession = async (
  selfLearnSessionId: number,
  param: CourseSession,
) => {
  const { data } = await Axios.patch<CourseSessionData>(
    `/selfLearns/sessions/${selfLearnSessionId}`,
    param,
  );
  return data;
};

export const updateCourseSessionOrder = async (
  selfLearnCourseId: number,
  param: CourseSessionOrder[],
) => {
  const { data } = await Axios.put<null>(
    `/selfLearns/${selfLearnCourseId}/sessions/ordering`,
    param,
  );
  return data;
};

//
// Test
//

export interface GetTestListParams extends Filter<TestData> {
  description?: string;
}

export const getTestList = async (params: GetTestListParams) => {
  const { data } = await Axios.get<ListResult<TestData>>('/selfLearns/test', {
    params,
  });
  return data;
};

export interface GetTestSubmissionListParams
  extends Filter<TestSubmissionData> {
  selfLearnTestId?: number;
  userId?: number;
}

export const getTestSubmissionList = async (
  params: GetTestSubmissionListParams,
) => {
  const { data } = await Axios.get<ListResult<TestSubmissionData>>(
    '/selfLearns/test/submission',
    {
      params,
    },
  );
  return data;
};

export const getTestSubmissionData = async (
  selfLearnTestSubmissionId: number,
) => {
  const { data } = await Axios.get<TestSubmissionData>(
    `/selfLearns/test/submission/${selfLearnTestSubmissionId}`,
  );
  return data;
};

export const getTestData = async (selfLearnTestId: number) => {
  const { data } = await Axios.get<TestData>(
    `/selfLearns/test/${selfLearnTestId}`,
  );
  return data;
};

export const createTest = async (param: Test) => {
  const { data } = await Axios.post<TestData>('/selfLearns/test', param);
  return data;
};

export const deleteTest = async (selfLearnTestId: number) => {
  const { data } = await Axios.delete(`/selfLearns/test/${selfLearnTestId}`);
  return data;
};

export const updateTest = async (selfLearnTestId: number, param: Test) => {
  const { data } = await Axios.patch<TestData>(
    `/selfLearns/test/${selfLearnTestId}`,
    param,
  );
  return data;
};

/*
 **  Get one the self learn video (https://menspace-app-dev.appicidea.com/doc/v2#get-/v1/selfLearns/video)
 */

export const getSelfLearnVideo = async () => {
  const { data } = await Axios.get<SelfLearnVideo>(`/selfLearns/video`);
  return data;
};

/*
 **  Create or update new self learn video, admin only (https://menspace-app-dev.appicidea.com/doc/v2#put-/v1/selfLearns/video)
 */

export interface putSelfLearnVideoParams {
  video: string;
}

export const putSelfLearnVideo = async (params: putSelfLearnVideoParams) => {
  const { data } = await Axios.put<SelfLearnVideo>(`/selfLearns/video`, params);
  return data;
};
