import { OptionItems } from 'app/models';

export const SUGGESTION_OPTIONS: OptionItems[] = [
  {
    label: '活動報名',
    value: '活動報名',
  },
  {
    label: '探索男士討論區',
    value: '探索男士主場',
  },
  {
    label: '參加男士聊天室',
    value: '參加男生圍',
  },
  {
    label: '自學課程',
    value: '自學自療課程',
  },
  {
    label: '24小時求助熱線',
    value: '24小時求助熱線',
  },
  {
    label: '精神健康資訊',
    value: '精神健康資訊',
  },
  {
    label: '精神健康綜合社區中心資訊',
    value: '精神健康綜合社區中心資訊',
  },
  {
    label: '預約個人輔導服務',
    value: '預約個人輔導服務',
  },
];
