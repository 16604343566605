import { AdminFormPage, AdminListPage } from 'app/pages/AdminPage';
import { LoginPage, LogoutPage } from 'app/pages/Authentication';
import {
  EventCategoryListPage,
  EventFormPage,
  EventListPage,
} from 'app/pages/EventPage';
import { EmergencyFormPage, EmergencyListPage } from 'app/pages/EmergencyPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import {
  NotificationListPage,
  NotificationFormPage,
} from 'app/pages/NotificationPage';
import { QuoteFormPage, QuoteListPage } from 'app/pages/QuotePage';
import {
  ResourceCategoryListPage,
  ResourceFormPage,
  ResourceListPage,
} from 'app/pages/ResourcePage';
import { UserListPage } from 'app/pages/UserPage';
import { Redirect } from 'react-router-dom';
import {
  ConsultationApplicationListPage,
  ConsultationFormPage,
  ConsultationHolidayFormPage,
  ConsultationListPage,
  ConsultationHolidayListPage,
  ConsultationCalendarPage,
  ConsultationRoomPage,
} from 'app/pages/ConsultationPage';
import {
  CourseDetailPage,
  CourseFormPage,
  CourseListPage,
  CourseTestFormPage,
  CourseTestListPage,
  SelfLearnVideoFormPage,
} from 'app/pages/CoursePage';
import {
  ForumKeywordFormPage,
  ForumKeywordListPage,
  ForumPostDetailPage,
  ForumPostListPage,
  ForumReportListPage,
  ForumTopicListPage,
} from 'app/pages/ForumPage';
import { UserDetailPage } from 'app/pages/UserPage/UserDetailPage';
import {
  PHQFormPage,
  PHQListPage,
  PHQSubmissionDetailPage,
  PHQSubmissionListPage,
} from 'app/pages/PHQPage';
import { EventApplicationListPage } from 'app/pages/EventPage/EventApplicationListPage';
import {
  CourseTestSubmissionListPage,
  CourseTestSubmissionDetailPage,
  CourseSubmissionListPage,
} from 'app/pages/CoursePage';
import {
  GroupChatFormPage,
  GroupChatListPage,
  GroupChatRoomPage,
  GroupChatReportListPage,
} from 'app/pages/GroupChatPage';
import { AdminRole } from 'app/models';
import {
  BotChatListPage,
  QAListPage,
  QAFormPage,
  BotChatRoomPage,
} from 'app/pages/BotChatPage';
import PrivacyPolicyPage from 'app/pages/PrivacyPolicyPage';
import { TakeDownPage } from 'app/pages/TakeDownFormPage/loadable';
import { TakeDownDetailPage, TakeDownListPage } from 'app/pages/TakeDownPage';
import {
  BannerFormPage,
  BannerListPage,
  BannerSortPage,
  PopupBannerFormPage,
} from 'app/pages/BannerPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
  permission?: AdminRole[];
}

const userRoutes: Route[] = [
  // courses
  { path: '/courses', component: CourseListPage, permission: ['超級管理員'] },
  {
    path: '/courses/new',
    component: CourseFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/courses/view/:selfLearnCourseid',
    component: CourseDetailPage,
    permission: ['超級管理員'],
  },
  {
    path: '/courses/edit/:selfLearnCourseid',
    component: CourseFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/course-tests',
    component: CourseTestListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/course-tests/new',
    component: CourseTestFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/course-tests/edit/:selfLearnTestid',
    component: CourseTestFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/course-submissions',
    component: CourseSubmissionListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/course-test-submissions',
    component: CourseTestSubmissionListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/course-test-submissions/view/:selfLearnTestSubmissionid',
    component: CourseTestSubmissionDetailPage,
    permission: ['超級管理員'],
  },
  {
    path: '/self-learn-video',
    component: SelfLearnVideoFormPage,
    permission: ['超級管理員'],
  },
  // events
  { path: '/events', component: EventListPage, permission: ['超級管理員'] },
  { path: '/events/new', component: EventFormPage, permission: ['超級管理員'] },
  {
    path: '/events/edit/:eventid',
    component: EventFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/event-applications',
    component: EventApplicationListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/event-categories',
    component: EventCategoryListPage,
    permission: ['超級管理員'],
  },
  // resources
  {
    path: '/resources',
    component: ResourceListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/resources/new',
    component: ResourceFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/resources/edit/:resourceid',
    component: ResourceFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/resource-categories',
    component: ResourceCategoryListPage,
    permission: ['超級管理員'],
  },
  // forum
  {
    path: '/forum/topics',
    component: ForumTopicListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/forum/posts',
    component: ForumPostListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/forum/posts/view/:forumPostid',
    component: ForumPostDetailPage,
    permission: ['超級管理員'],
  },
  {
    path: '/forum/reports',
    component: ForumReportListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/forum/sensitive-keywords',
    component: ForumKeywordListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/forum/sensitive-keywords/new',
    component: ForumKeywordFormPage,
    permission: ['超級管理員'],
  },
  // phq
  { path: '/phq', component: PHQListPage, permission: ['超級管理員'] },
  { path: '/phq/new', component: PHQFormPage, permission: ['超級管理員'] },
  {
    path: '/phq/edit/:phqid',
    component: PHQFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/phq-submissions',
    component: PHQSubmissionListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/phq-submissions/view/:phqSubmissionid',
    component: PHQSubmissionDetailPage,
    permission: ['超級管理員'],
  },
  // consultations
  {
    path: '/consultations',
    component: ConsultationListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultations/new',
    component: ConsultationFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultations/edit/:consultationSlotid',
    component: ConsultationFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultation-holidays',
    component: ConsultationHolidayListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultation-holidays/new',
    component: ConsultationHolidayFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultation-holidays/edit/:consultationHolidayid',
    component: ConsultationHolidayFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultation-calendar',
    component: ConsultationCalendarPage,
    permission: ['超級管理員'],
  },
  {
    path: '/consultation-applications',
    component: ConsultationApplicationListPage,
    permission: ['超級管理員', '職員'],
  },
  {
    path: '/consultation-applications/:applicationId/room/:roomId',
    component: ConsultationRoomPage,
    permission: ['超級管理員', '職員'],
  },
  // emergency contacts
  {
    path: '/emergency-contacts',
    component: EmergencyListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/emergency-contacts/new',
    component: EmergencyFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/emergency-contacts/edit/:emergencyid',
    component: EmergencyFormPage,
    permission: ['超級管理員'],
  },
  // notification
  {
    path: '/notifications',
    component: NotificationListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/notifications/new',
    component: NotificationFormPage,
    permission: ['超級管理員'],
  },
  // daily quotes
  {
    path: '/daily-quotes',
    component: QuoteListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/daily-quotes/new',
    component: QuoteFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/daily-quotes/edit/:dailyQuoteid',
    component: QuoteFormPage,
    permission: ['超級管理員'],
  },
  // group chat
  {
    path: '/group-chats',
    component: GroupChatListPage,
    permission: ['超級管理員', '職員'],
  },
  {
    path: '/group-chats-reports',
    component: GroupChatReportListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/group-chats/new',
    component: GroupChatFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/group-chats/edit/:GroupChatId',
    component: GroupChatFormPage,
    permission: ['超級管理員'],
  },
  {
    path: '/group-chats/:GroupChatId/:roomId',
    component: GroupChatRoomPage,
    permission: ['超級管理員', '職員'],
  },

  //Banner
  { path: '/banners', component: BannerListPage },
  { path: '/banners/:type', component: BannerFormPage },
  { path: '/banners/:type/:bannerId', component: BannerFormPage },
  { path: '/banners-sort', component: BannerSortPage },
  { path: '/popup-banner', component: PopupBannerFormPage },
  // admin
  { path: '/admins', component: AdminListPage, permission: ['超級管理員'] },
  {
    path: '/admins/new',
    component: AdminFormPage,
    exact: true,
    permission: ['超級管理員'],
  },
  {
    path: '/admins/edit/:adminid',
    component: AdminFormPage,
    exact: true,
    permission: ['超級管理員'],
  },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
    exact: true,
    permission: ['超級管理員'],
  },
  // user
  { path: '/users', component: UserListPage, permission: ['超級管理員'] },
  {
    path: '/users/view/:userid',
    component: UserDetailPage,
    permission: ['超級管理員'],
  },

  //botChat
  {
    path: '/botChat',
    component: BotChatListPage,
    permission: ['超級管理員', '職員'],
  },
  { path: '/botChat-QA', component: QAListPage, permission: ['超級管理員'] },
  {
    path: '/botChat-QA/new',
    component: QAFormPage,
    exact: true,
    permission: ['超級管理員'],
  },
  {
    path: '/botChat-QA/edit/:chatQAId',
    component: QAFormPage,
    exact: true,
    permission: ['超級管理員'],
  },
  {
    path: '/botChat/:botChatId/room/:roomId',
    component: BotChatRoomPage,
    permission: ['超級管理員', '職員'],
  },

  // takedown
  {
    path: '/take-down',
    component: TakeDownListPage,
    permission: ['超級管理員'],
  },
  {
    path: '/take-down/detail/:takedownId',
    component: TakeDownDetailPage,
    permission: ['超級管理員'],
  },

  // others
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/terms-and-privacy', component: PrivacyPolicyPage },
  { path: '/takedown', component: TakeDownPage },
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
