import { Filter, ListResult, User, UserLog, UserPointRecord } from '../models';
import Axios from './';

export interface GetUserListParams extends Filter<User> {
  q: string;
  email: string;
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/users', { params });
  return data;
};

export const getUser = async (userId: number | string) => {
  const { data } = await Axios.get<User>(`/users/${userId}`);
  return data;
};

export interface GetUserPointRecordListParams extends Filter<UserPointRecord> {}

export const getUserPointRecordList = async (
  userId: number,
  params: GetUserPointRecordListParams,
) => {
  const { data } = await Axios.get<ListResult<UserPointRecord>>(
    `/users/${userId}/pointHistory`,
    { params },
  );
  return data;
};

export const deactivateOneUser = async (userId: number | string) => {
  const { data } = await Axios.post<any>(`/users/${userId}/deactivate`);
  return data;
};

export const activateOneUser = async (userId: number | string) => {
  const { data } = await Axios.post<any>(`/users/${userId}/activate`);
  return data;
};

export const getUserLog = async (userId: number | string) => {
  const { data } = await Axios.get<ListResult<UserLog>>(`/users/${userId}/log`);
  return data;
};
