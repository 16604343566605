/**
 *
 * Asynchronously loads the component for GroupChatFormPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const GroupChatFormPage = lazyLoad(
  () => import('./index'),
  module => module.GroupChatFormPage,
);
