import { AdminRole } from 'app/models';
import { Redirect, Route } from 'react-router-dom';
import { useAdminPermission } from 'store/Permission';

interface Props {
  component: any;
  isAuthProtected: boolean;
  layout?: any;
  exact?: boolean;
  path: string;
  permission?: AdminRole[];
}

const ProtectedRoute = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  exact,
  path,
  permission,
  ...rest
}: Props) => {
  const { hasPermission } = useAdminPermission();
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={props => {
        if (permission && !hasPermission(permission)) {
          return <Redirect to={{ pathname: '/notFound' }} />;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default ProtectedRoute;
