import { CourseSubmission, CourseSubmissionData } from 'app/models';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'reactstrap';
import { InputField } from '../Form';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { updateCourseSubmission } from 'app/services/CourseService';
import * as Sentry from '@sentry/react';

interface Props {
  data: CourseSubmissionData;
  afterUpdate: () => void;
}

const Schema = Yup.object().shape({
  displayName: Yup.string()
    .nullable()
    .min(1, '最少要1個字元')
    .max(20, '最多20字元')
    .required('必填'),
});

const FORM_ITEM = {
  displayName: '',
};

interface FormItem {
  displayName: string;
}

export const CourseSubmissionForm = (props: Props) => {
  const { data, afterUpdate } = props;
  const [initLoad, setInitLoad] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);
  const formRef = useRef<FormikProps<FormItem>>(null);

  const initForm = useCallback(async () => {
    try {
      if (data.displayName) {
        setForm({
          displayName: data.displayName,
        });
      }
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      setInitLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSubmission = async (params: CourseSubmission) => {
    try {
      await updateCourseSubmission(data.selfLearnSubmissionId, params);
      toast.success('編輯證書顯示名稱成功。');
      afterUpdate();
    } catch (err) {
      toast.warning('編輯證書顯示名稱失敗，請重試。');
      console.log(err);
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    const params = {
      ...values,
    };
    updateSubmission(params);
  };

  useEffect(() => {
    initForm();
  }, [initForm]);

  return (
    <>
      {initLoad ? (
        <div className="p-2">
          <Formik
            initialValues={form}
            validationSchema={Schema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ values }) => (
              <Form className="form-horizontal">
                <Row className="mb-3">
                  <Col md={12}>
                    <InputField
                      name="displayName"
                      label="證書顯示名稱"
                      placeholder="證書顯示名稱"
                    />
                  </Col>
                </Row>
                <div className="mt-3 d-flex flex-row-reverse">
                  <Button
                    type="submit"
                    color="primary"
                    className="ms-1"
                    disabled={loading}
                    onClick={e => {
                      e.preventDefault();
                      formRef.current?.handleSubmit();
                    }}
                  >
                    {loading ? (
                      <i className="bx bx-loader-circle bx-spin" />
                    ) : (
                      '提交'
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
};
