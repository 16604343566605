import { EventApplicationData } from 'app/models';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Badge } from 'reactstrap';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';
import { formatNumber } from 'app/helpers/CommonHelper';

const EventApplicationListColumns = (
  onDeleteButtonClick: (value: number) => void,
  onEditButtonClick: (data: EventApplicationData) => void,
) => [
  {
    text: '活動',
    dataField: 'Course',
    formatter: (application: EventApplicationData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{application.Course.title}</div>
        </h5>
      </>
    ),
  },
  {
    text: '活動日期',
    dataField: 'Course',
    formatter: (application: EventApplicationData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(application.Course.courseDate).format('YYYY-MM-DD')}
          </div>
          <div className="text-muted">
            {application?.Course?.courseStartTime
              ? moment(application?.Course?.courseStartTime, 'HH:mm:ss').format(
                  'HH:mm',
                )
              : '-'}{' '}
            -{' '}
            {application?.Course?.courseEndTime
              ? moment(application?.Course?.courseEndTime, 'HH:mm:ss').format(
                  'HH:mm',
                )
              : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '活動費用',
    dataField: 'pointUsed',
    canSort: true,
    formatter: (application: EventApplicationData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{`${
            application?.Course?.courseFee
              ? formatNumber(application?.Course?.courseFee)
              : 0
          }積分`}</div>
        </h5>
      </>
    ),
  },
  {
    text: '用戶名稱',
    dataField: 'User',
    formatter: (application: EventApplicationData) => (
      <div className="d-flex">
        <Tooltip
          placement="top"
          overlayStyle={{ padding: '5px 0 5px 0' }}
          overlay={<div className="px-3 py-1 fs-6">查看用戶資料</div>}
          overlayInnerStyle={{ borderRadius: '4px' }}
        >
          <Link
            to={`/users/view/${application.userId}`}
            data-for={'view' + application.userId}
            data-tip
          >
            <h5 className="font-size-14 mb-1">
              <div className="text-primary">
                {application?.User?.displayName}
              </div>
            </h5>
          </Link>
        </Tooltip>
      </div>
    ),
  },
  {
    text: '申請狀態',
    dataField: 'status',
    formatter: (application: EventApplicationData) => (
      <>
        <h5 className="font-size-14 mb-1">
          {application.status === 'Approved' ? (
            <Badge
              pill
              className={`badge-soft-success px-2 py-1 mt-1 font-size-12 rounded-pill`}
              color="none"
            >
              已通過
            </Badge>
          ) : application.status === 'Cancelled' ? (
            <Badge
              pill
              className={`badge-soft-danger px-2 py-1 mt-1 font-size-12 rounded-pill`}
              color="none"
            >
              已取消
            </Badge>
          ) : application.status === 'Pending' ? (
            <Badge
              pill
              className={`badge-soft-warning px-2 py-1 mt-1 font-size-12 rounded-pill`}
              color="none"
            >
              處理中
            </Badge>
          ) : (
            <Badge
              pill
              className={`badge-soft-primary px-2 py-1 mt-1 font-size-12 rounded-pill`}
              color="none"
            >
              已完成
            </Badge>
          )}
        </h5>
      </>
    ),
  },
  {
    text: '申請日期',
    dataField: 'createdAt',
    canSort: true,
    formatter: (application: EventApplicationData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(application.createdAt).format('YYYY-MM-DD HH:mm')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (application: EventApplicationData) => (
      <ul className="list-inline font-size-20 mb-0">
        <li className="list-inline-item px-2">
          <button
            className="notification-list-button custom-btn"
            onClick={() => onEditButtonClick(application)}
            data-for={'edit' + application.courseApplicationId}
            data-tip
          >
            <i className="bx bx-edit-alt" />
            <ReactTooltip
              effect="solid"
              id={'edit' + application.courseApplicationId}
            >
              編輯活動申請
            </ReactTooltip>
          </button>
        </li>
        <li className="list-inline-item px-2">
          <button
            className="notification-list-button custom-btn"
            onClick={() => onDeleteButtonClick(application.courseApplicationId)}
            // disabled={application.status === 'Accepted'}
            data-for={'delete' + application.courseApplicationId}
            data-tip
          >
            <i className="mdi mdi-delete-outline" />
            <ReactTooltip
              effect="solid"
              id={'delete' + application.courseApplicationId}
            >
              刪除活動申請
            </ReactTooltip>
          </button>
        </li>
      </ul>
    ),
  },
];

export default EventApplicationListColumns;
