import { useEffect } from 'react';
import { db } from 'app/services/FirebaseService';
import {
  DocumentData,
  QuerySnapshot,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { CustomChatRoom } from 'app/models';
import { formatChatRooms } from './useChatRoom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setHasUnreadBotChat } from 'store/Layout';
import * as Sentry from '@sentry/react';

export const useBotChatListener = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((rootState: RootState) => rootState.auth);

  const handleChatRoomDataDocs = async (snap: QuerySnapshot<DocumentData>) => {
    try {
      const formattedChatRooms: CustomChatRoom[] = await formatChatRooms(
        snap,
        profile,
      );
      if (
        formattedChatRooms &&
        formattedChatRooms.length &&
        formattedChatRooms[0].hasUnreadMessage
      ) {
        dispatch(setHasUnreadBotChat(true));
      } else {
        dispatch(setHasUnreadBotChat(false));
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    let unsubscribeBotChatListener: any = null;

    const onLoadMessage = async (snap: QuerySnapshot<DocumentData>) => {
      handleChatRoomDataDocs(snap);
    };

    const onError = (error: FirebaseError) => {
      console.log(error);
    };

    if (profile) {
      const q = query(
        collection(db, `rooms`),
        where('type', '==', 'botChat'),
        orderBy('lastMessage.createdAt', 'desc'),
        limit(1),
      );
      unsubscribeBotChatListener = onSnapshot<DocumentData>(
        q,
        onLoadMessage,
        onError,
      );
    }

    return () => {
      if (unsubscribeBotChatListener) {
        unsubscribeBotChatListener();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, profile]);
};
