import { User, UserLog, UserPointRecord } from 'app/models';
import {
  activateOneUser,
  deactivateOneUser,
  getUserPointRecordList,
  GetUserPointRecordListParams,
} from 'app/services/UserService';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import UserPointListColumns from './UserPointListColumns';
import { toast } from 'react-toastify';
import TableCount from '../Common/TableCount';
import { Pagination, Table as CustomTable } from 'app/components/Table';
import { Link, useLocation } from 'react-router-dom';
import Dialog from '../Dialog';
import { UserAvatar } from './UserAvatar';
import * as Sentry from '@sentry/react';
import { formatNumber } from 'app/helpers/CommonHelper';

interface Props {
  data: User;
  log?: UserLog;
  refreshUser: () => void;
}

export const UserInfoCard = (props: Props) => {
  const { data, log, refreshUser } = props;
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const [activateLoading, setActivateLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [recordList, setRecordList] = useState<UserPointRecord[]>();
  const [filter, setFilter] = useState<GetUserPointRecordListParams>({
    limit: 20,
    page: 0,
  });

  const initPage = useCallback(async () => {
    try {
      setLoading(true);
      const { count, rows } = await getUserPointRecordList(data.userId, filter);
      setRecordList(rows);
      setCount(count);
    } catch (err) {
      console.log(err);
      toast.error('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }, [data.userId, filter]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const p = params.get('page');
    if (p) {
      setFilter({ ...filter, page: Number(p) - 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    initPage();
  }, [initPage]);

  const deactivate = async () => {
    try {
      setActivateLoading(true);
      await deactivateOneUser(data.userId);
      refreshUser();
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    } finally {
      setVisible(false);
      setActivateLoading(false);
    }
  };

  const activate = async () => {
    try {
      setActivateLoading(true);
      await activateOneUser(data.userId);
      refreshUser();
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    } finally {
      setVisible(false);
      setActivateLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col className="mb-2" sm={6}>
                  <CardTitle className="mb-4 fs-4">基本資料</CardTitle>
                </Col>

                {data?.userId ? (
                  <Col
                    className="mb-2 d-flex justify-content-end align-items-center"
                    sm={6}
                  >
                    <Link
                      className="btn btn-warning me-2"
                      to={`/forum/posts?userId=${data?.userId}&userIdLabel=%23${data?.userId}+|+${data.displayName}`}
                    >
                      <i className="mdi mdi-account-multiple-outline me-1"></i>
                      查看用戶帖子
                    </Link>
                    <button
                      className={`btn btn-${
                        data.status === '已啟用' ? 'danger' : 'success'
                      }`}
                      onClick={() => setVisible(true)}
                      disabled={activateLoading}
                    >
                      {data.status === '已啟用' ? (
                        <i className="mdi mdi-block-helper me-1"></i>
                      ) : (
                        <i className="mdi mdi-account-check-outline me-1"></i>
                      )}

                      {activateLoading ? (
                        <i className="bx bx-loader-circle bx-spin" />
                      ) : data.status === '已啟用' ? (
                        '封鎖會員帳號'
                      ) : (
                        '啟用會員帳號'
                      )}
                    </button>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="user-info d-flex justify-content-start align-items-center">
                    <UserAvatar
                      avatar={data?.avatar}
                      alt={data?.displayName ? data?.displayName : ''}
                    />
                    <div className="table-responsive ms-2">
                      <Table className="custom-table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">名稱 :</th>
                            <td className="d-flex flex-row">
                              <div className="me-1">{data.displayName}</div>
                              <div>
                                {data.status === '已啟用' ? (
                                  <Badge
                                    pill
                                    className={`badge-soft-success px-2 py-1 font-size-12 rounded-pill`}
                                    color="none"
                                  >
                                    已啟用
                                  </Badge>
                                ) : (
                                  <Badge
                                    pill
                                    className={`badge-soft-danger px-2 py-1 font-size-12 rounded-pill`}
                                    color="none"
                                  >
                                    已停用
                                  </Badge>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">編號 :</th>
                            <td>
                              <div>{data.userId}</div>
                            </td>
                          </tr>
                          <tr className="mt-3">
                            <th scope="row">積分 :</th>
                            <td>
                              <div>
                                {data.point ? formatNumber(data.point) : 0}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0 d-flex custom-table">
                      <tbody>
                        <tr>
                          <th scope="row">性別 :</th>
                          <td>
                            <div>{data.gender}</div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">年齡 :</th>
                          <td>
                            <div>{data.age}</div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">創建日期 :</th>
                          <td>
                            {moment(data?.createdAt).format('YYYY-MM-DD')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4 fs-4">聯絡資料</CardTitle>
              <Row>
                <Col md={4}>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0 custom-table">
                      <tbody>
                        <tr>
                          <th scope="row">電子郵件 :</th>
                          <td>
                            <div>{data?.email}</div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">電話號碼 :</th>
                          <td>
                            <div>{`${data.countryCallingCode} ${data.contactPhone}`}</div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">是否接收消息 :</th>
                          <td>{data.isReceiveNotification ? '是' : '否'}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4 fs-4">登入紀錄</CardTitle>
              <Row>
                <Col md={6}>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0 custom-table">
                      <tbody>
                        <tr>
                          <th scope="row">登入方式 :</th>
                          <td>
                            <div>{log?.device || '沒有紀錄'}</div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">上次登入IP :</th>
                          <td>
                            <div>{log?.ip || '沒有紀錄'}</div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle className=" fs-4">積分歷史</CardTitle>
              {!loading ? (
                recordList?.length ? (
                  <>
                    <Row>
                      <Col
                        sm={12}
                        className="d-flex align-self-end justify-content-end mt-3"
                      >
                        <TableCount count={count} title="積分記錄總數" />
                      </Col>
                      <Col md={12}>
                        <div className="table-responsive">
                          <CustomTable
                            keyField="userPointHistoryId"
                            columns={UserPointListColumns(data)}
                            data={recordList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination
                          count={count}
                          current={filter.page ? filter.page + 1 : 1}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  '暫時沒有積分記錄'
                )
              ) : (
                <i className="bx bx-loader-circle bx-spin" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog
        visible={visible}
        title={`${data.status === '已啟用' ? '封鎖' : '啟用'}會員帳號`}
        onClose={() => setVisible(false)}
        loading={activateLoading}
        onConfirm={() => (data.status === '已啟用' ? deactivate() : activate())}
      >
        <div>{`${
          data.status === '已啟用'
            ? '您確定要封鎖會員帳號嗎？一但封鎖，該會員會立即被登出，所會員功能會被停用。'
            : '您確定要啟用會員帳號嗎？'
        }`}</div>
      </Dialog>
    </>
  );
};
