import { User, UserPointRecord } from 'app/models';
import moment from 'moment';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { formatNumber } from 'app/helpers/CommonHelper';

const UserPointListColumns = (user: User) => [
  {
    text: '積分記錄編號',
    dataField: 'userPointHistoryId',
    canSort: true,
    formatter: (record: UserPointRecord) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{record.userPointHistoryId}</div>
        </h5>
      </>
    ),
  },
  {
    text: '類型',
    dataField: 'type',
    canSort: true,
    formatter: (record: UserPointRecord) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {record.type === 'Course'
              ? `活動`
              : record.type === 'PHQ'
              ? `心靈健康評估`
              : record.type === 'SelfLearn'
              ? `課程`
              : record.type}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '名稱',
    dataField: 'type',
    formatter: (record: UserPointRecord) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {record.type === 'Course'
              ? record.CourseApplication.Course.title
              : record.type === 'PHQ' && record.PHQSubmission?.PHQ
              ? record.PHQSubmission?.PHQ?.title
              : record.type === 'SelfLearn'
              ? record?.SelfLearnSubmission?.SelfLearnCourse?.title
              : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '相應編號',
    dataField: 'userPointHistoryId',
    sort: false,
    formatter: (record: UserPointRecord) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {`${
              record.type === 'Course'
                ? `活動申請`
                : record.type === 'PHQ'
                ? `評估提交`
                : record.type === 'SelfLearn'
                ? `課程提交`
                : record.type
            }編號 : ${
              record.type === 'Course'
                ? record.courseApplicationId
                : record.type === 'PHQ' && record.phqSubmissionId
                ? record.phqSubmissionId
                : record.type === 'SelfLearn'
                ? record.selfLearnSubmissionId
                : '-'
            }`}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '積分記錄',
    dataField: 'point',
    canSort: true,
    formatter: (record: UserPointRecord) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div
            className={`text-${record?.point > 0 ? 'success' : 'danger'}`}
          >{`${record.point ? formatNumber(record.point) : 0}積分`}</div>
        </h5>
      </>
    ),
  },
  {
    text: '記錄日期',
    dataField: 'createdAt',
    canSort: true,
    formatter: (record: UserPointRecord) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(record.createdAt).format('YYYY-MM-DD')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (record: UserPointRecord) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <Link
            to={
              record.type === 'Course'
                ? `/events?title=${record.CourseApplication.Course.title}`
                : record.type === 'PHQ' && record.PHQSubmission
                ? `/phq-submissions/view/${record.PHQSubmission.phqSubmissionId}`
                : record.type === 'SelfLearn'
                ? `/course-submissions?selfLearnCourseId=${record?.SelfLearnSubmission?.selfLearnCourseId}&selfLearnCourseIdLabel=%23${record?.selfLearnSubmissionId}+|+${record?.SelfLearnSubmission?.SelfLearnCourse?.title}&userId=${record.userId}&userIdLabel=%23${record.userId}+|+${user.displayName}`
                : '/'
            }
            data-for={'view' + record.userPointHistoryId}
            data-tip
            className={
              (record.type === 'Course' && record?.CourseApplication?.Course) ||
              (record.type === 'PHQ' && record?.PHQSubmission) ||
              (record.type === 'SelfLearn' &&
                record?.SelfLearnSubmission?.selfLearnCourseId)
                ? ''
                : `nav-link disabled`
            }
          >
            <i className="mdi mdi-view-headline" />
            <ReactTooltip
              effect="solid"
              id={'view' + record.userPointHistoryId}
            >
              查看相應記錄
            </ReactTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default UserPointListColumns;
