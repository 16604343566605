import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FieldArray, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, CardTitle, Col, Form, Row } from 'reactstrap';
import {
  HTMLField,
  ImageField,
  InputField,
  SelectField,
  SwitchField,
  VideoField,
} from '../Form';
import { CourseFormType } from 'app/pages/CoursePage/CourseDetailPage';
import {
  createCourseSession,
  getCourseSessionData,
  updateCourseSession,
} from 'app/services/CourseService';
import { uploadFile, uploadImage } from 'app/services/CommonService';
import { getImageURL } from 'app/helpers/CommonHelper';
import { ConclusionType, CourseSessionOptionConclusion } from 'app/models';
import { CONCLUSION_OPTIONS } from 'app/const';
import ReactTooltip from 'react-tooltip';
import * as Sentry from '@sentry/react';

const Schema = Yup.object().shape({
  selfLearnCourseId: Yup.number().required('必填'),
  sessionName: Yup.string().nullable().min(2, '最少要2個字元').required('必填'),
  coverImage: Yup.mixed().nullable().required('必填'),
  video: Yup.mixed().nullable().required('必填'),
  question: Yup.string()
    .nullable()
    .min(2, '最少要2個字元')
    .max(200, '最多200字元')
    .required('必填'),
  description: Yup.string()
    .nullable()
    .min(2, '最少要2個字元')
    .max(10000, '最多10,000字元'),
  SelfLearnSessionOptions: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        optionTitle: Yup.string()
          .nullable()
          .min(2, '最少要2個字元')
          .required('必填'),
        video: Yup.mixed().nullable(),
        // color: Yup.string(),
        isCorrect: Yup.string().required('必填'),
        conclusions: Yup.array()
          .nullable()
          .of(
            Yup.object().shape({
              type: Yup.string().nullable().required('必填'),
              html: Yup.string()
                .nullable()
                .when('type', {
                  is: 'HTML',
                  then: Yup.string().nullable().required('必填'),
                  otherwise: Yup.string().nullable(),
                }),
              question: Yup.string()
                .nullable()
                .when('type', {
                  is: type => type !== 'HTML',
                  then: Yup.string().nullable().required('必填'),
                  otherwise: Yup.string().nullable(),
                }),
              answers: Yup.array()
                .of(Yup.string())
                .nullable()
                .when('type', {
                  is: type => type !== 'HTML',
                  then: Yup.array()
                    .of(Yup.string().required('必填'))
                    .nullable(),
                  otherwise: Yup.array().of(Yup.string()).nullable(),
                }),
            }),
          ),
      }),
    ),
});

interface CourseSession {
  selfLearnCourseId: number;
  sessionName: string;
  coverImage: any;
  video: any;
  question?: string;
  description?: string;
  SelfLearnSessionOptions: CustomOption[];
}

interface CustomOption {
  optionTitle: string;
  video?: any;
  // color?: string;
  conclusions: CourseSessionOptionConclusion[];
  isCorrect: boolean;
}

const FORM_ITEM = {
  selfLearnCourseId: 1,
  sessionName: '',
  coverImage: '',
  video: '',
  question: '',
  description: '',
  SelfLearnSessionOptions: [
    {
      optionTitle: '',
      video: '',
      // color: '#000000',
      conclusions: [
        {
          html: '',
          question: '',
          type: 'MC' as ConclusionType,
          answers: [''],
        },
      ],
      isCorrect: true,
    },
  ],
};

interface Props {
  courseId: number;
  id: number;
  formType: CourseFormType;
  afterUpdate: () => void;
}

export const CourseSessionForm = (props: Props) => {
  const { courseId, id, formType, afterUpdate } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [initLoad, setInitLoad] = useState<boolean>(false);
  const [form, setForm] = useState<CourseSession>(FORM_ITEM);
  const formRef = useRef<FormikProps<CourseSession>>(null);

  const initForm = useCallback(async () => {
    try {
      const res = await getCourseSessionData(id);
      const {
        selfLearnCourseId,
        sessionName,
        question,
        description,
        coverImage,
        video,
        SelfLearnSessionOptions,
      } = res;
      let tempOptions: CustomOption[] = [];
      for (let i = 0; i < SelfLearnSessionOptions.length; i++) {
        // for (const i in SelfLearnSessionOptions) {
        const { optionTitle, video, conclusions, isCorrect } =
          SelfLearnSessionOptions[i];
        let temp;
        if (video && video.length) {
          temp = {
            optionTitle,
            // color,
            conclusions,
            isCorrect,
            video: [
              {
                preview: getImageURL(video, 768),
                video: video,
                name: video.split('_').at(-1),
              },
            ],
          };
        } else {
          temp = {
            optionTitle,
            // color,
            conclusions,
            isCorrect,
            video,
          };
        }
        tempOptions.push(temp);
      }
      setForm({
        selfLearnCourseId,
        sessionName,
        question,
        description,
        coverImage: [
          {
            preview: getImageURL(coverImage, 768),
            image: coverImage,
            name: '當前圖像',
          },
        ],
        video: [
          {
            preview: getImageURL(video, 768),
            video: video,
            name: video.split('_').at(-1),
          },
        ],
        SelfLearnSessionOptions: tempOptions,
      });
      setInitLoad(true);
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const createNewSession = async (
    values: CourseSession,
    actions: FormikHelpers<CourseSession>,
  ) => {
    try {
      const params = await formatData(values);
      await createCourseSession(params);
      toast.success('新增課程單元成功。');
      afterUpdate();
    } catch (err: any) {
      toast.warning('新增課程單元失敗，請重試。');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  const updateSessionData = async (values: CourseSession) => {
    if (id) {
      try {
        const params = await formatData(values);
        await updateCourseSession(id, params);
        toast.success('編輯課程單元成功。');
        afterUpdate();
      } catch (err) {
        toast.warning('編輯課程單元失敗，請重試。');
        Sentry.captureException(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatData = async (values: CourseSession) => {
    const {
      sessionName,
      coverImage,
      video,
      question,
      description,
      SelfLearnSessionOptions,
    } = values;
    const tempOptions: CustomOption[] = [];
    const getNewOptions = async () => {
      // for (const i in SelfLearnSessionOptions) {
      for (let i = 0; i < SelfLearnSessionOptions.length; i++) {
        const { optionTitle, video, conclusions, isCorrect } =
          SelfLearnSessionOptions[i];
        const videoData = new FormData();
        let temp;
        if (video && video.length) {
          if (video[0].size) {
            videoData.append('file', video[0]);
            const videoRes = await uploadFile(videoData);
            temp = {
              optionTitle,
              // color,
              conclusions,
              isCorrect,
              video: videoRes.fileName,
            };
          } else {
            temp = {
              optionTitle,
              // color,
              conclusions,
              isCorrect,
              video: video[0].video,
            };
          }
        } else {
          temp = {
            optionTitle,
            // color,
            conclusions,
            isCorrect,
            video,
          };
        }
        tempOptions.push(temp);
      }
    };
    await getNewOptions();
    const params = {
      selfLearnCourseId: courseId,
      sessionName,
      coverImage,
      video,
      question,
      description,
      SelfLearnSessionOptions: tempOptions,
    };
    if (video[0].size) {
      const formData = new FormData();
      formData.append('file', video[0]);
      const videoRes = await uploadFile(formData);
      params.video = videoRes.fileName;
    } else {
      params.video = video[0].video;
    }
    if (coverImage) {
      if (coverImage && coverImage.length > 0) {
        const imgArr: string[] = [];
        for (let i = 0; i < coverImage.length; i++) {
          const formData = new FormData();
          if (coverImage[i].size) {
            formData.append(`file`, coverImage[i]);
            const coverImageRes = await uploadImage(formData);
            imgArr.push(coverImageRes.fileName);
          } else {
            imgArr.push(coverImage[i].image);
          }
        }
        params.coverImage = imgArr[0];
      } else {
        params.coverImage = coverImage[0].image;
      }
    }
    return params;
  };

  const onSubmit = async (
    values: CourseSession,
    actions: FormikHelpers<CourseSession>,
  ) => {
    setLoading(true);
    if (formType === 'create') {
      createNewSession(values, actions);
    } else {
      updateSessionData(values);
    }
  };

  useEffect(() => {
    if (formType !== 'create') {
      initForm();
    } else {
      setInitLoad(true);
    }
  }, [initForm, id, formType]);

  return (
    <>
      {initLoad ? (
        <div className="p-2">
          <Formik
            initialValues={form}
            validationSchema={Schema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ values, errors }) => (
              <Form className="form-horizontal">
                <Row className="mb-3">
                  <Col md={12}>
                    <InputField
                      name="sessionName"
                      label="單元標題"
                      placeholder="標題"
                    />
                  </Col>
                  <Col md={12}>
                    <ImageField
                      className="mb-4"
                      name="coverImage"
                      label="封面圖像 (長寬比 16:9)"
                    />
                  </Col>
                  <Col md={12}>
                    <VideoField
                      name="video"
                      label="單元影片"
                      placeholder="影片"
                      type="file"
                    />
                  </Col>
                  <Col md={12}>
                    <InputField
                      name="question"
                      label="單元問題"
                      placeholder="問題"
                    />
                  </Col>
                  <Col md={12}>
                    <HTMLField
                      name="description"
                      label="單元描述"
                      theme="dark"
                    />
                  </Col>
                  <Col md={12}>
                    <div
                      className="mt-5 mb-2"
                      style={{
                        borderBottom: 'dashed',
                        opacity: '0.2',
                      }}
                    ></div>
                  </Col>
                  <Col md={12}>
                    <FieldArray
                      name="SelfLearnSessionOptions"
                      render={arrayHelpers => (
                        <>
                          {values.SelfLearnSessionOptions.map((opt, index) => (
                            <div key={index}>
                              <Row className="mt-5">
                                <Col md={12} className="mt-2">
                                  <CardTitle>選項 {index + 1}</CardTitle>
                                  <hr />
                                </Col>
                                <Col sm={10}>
                                  <InputField
                                    name={`SelfLearnSessionOptions.${index}.optionTitle`}
                                    label={`選項描述`}
                                    placeholder={'選項描述'}
                                    type={'text'}
                                  />
                                </Col>
                                <Col sm={2}>
                                  <SwitchField
                                    name={`SelfLearnSessionOptions.${index}.isCorrect`}
                                    label="是否正確選項"
                                  />
                                </Col>
                                <Col sm={12}>
                                  <VideoField
                                    name={`SelfLearnSessionOptions.${index}.video`}
                                    label="影片"
                                    placeholder="影片"
                                  />
                                </Col>
                                {/* <Col sm={12}>
                                  <InputField
                                    name={`SelfLearnSessionOptions.${index}.color`}
                                    label="選項顏色"
                                    placeholder="選項顏色"
                                    type="color"
                                  />
                                </Col> */}
                                <Col sm={12}>
                                  <CardTitle>
                                    選項 {index + 1} - 小總結
                                  </CardTitle>
                                  <hr />
                                  <FieldArray
                                    name={`SelfLearnSessionOptions.${index}.conclusions`}
                                    render={arrayHelpers => (
                                      <>
                                        {values.SelfLearnSessionOptions[index]
                                          .conclusions?.length &&
                                          values.SelfLearnSessionOptions[
                                            index
                                          ].conclusions.map((conc, i) => (
                                            <div key={i}>
                                              {values.SelfLearnSessionOptions[
                                                index
                                              ].conclusions.length > 1 ? (
                                                <Row className="d-flex justify-content-end mb-0 mt-4">
                                                  <Col
                                                    md={12}
                                                    className="d-flex justify-content-end mt-3"
                                                  >
                                                    <Button
                                                      type="button"
                                                      color="warning"
                                                      className="me-1"
                                                      disabled={i === 0}
                                                      onClick={() =>
                                                        arrayHelpers.move(
                                                          i,
                                                          i - 1,
                                                        )
                                                      }
                                                    >
                                                      <i className="bx bx-up-arrow-alt" />
                                                    </Button>
                                                    <Button
                                                      type="button"
                                                      color="warning"
                                                      className="me-1"
                                                      disabled={
                                                        i ===
                                                        values
                                                          .SelfLearnSessionOptions[
                                                          index
                                                        ].conclusions.length -
                                                          1
                                                      }
                                                      onClick={() =>
                                                        arrayHelpers.move(
                                                          i,
                                                          i + 1,
                                                        )
                                                      }
                                                    >
                                                      <i className="bx bx-down-arrow-alt" />
                                                    </Button>
                                                    {/* <Button
                                                      type="button"
                                                      color="danger"
                                                      className="w-25"
                                                      onClick={() =>
                                                        arrayHelpers.remove(i)
                                                      }
                                                      disabled={
                                                        values
                                                          .SelfLearnSessionOptions[
                                                          index
                                                        ].conclusions.length <=
                                                        1
                                                      }
                                                    >
                                                      移除小總結內容
                                                    </Button> */}
                                                    <button
                                                      style={{ fontSize: 20 }}
                                                      disabled={
                                                        values
                                                          .SelfLearnSessionOptions[
                                                          index
                                                        ].conclusions.length <=
                                                        1
                                                      }
                                                      className="custom-btn"
                                                      onClick={() =>
                                                        arrayHelpers.remove(
                                                          index,
                                                        )
                                                      }
                                                      data-for={
                                                        `del-conclusion-${index}` +
                                                        i
                                                      }
                                                      data-tip
                                                    >
                                                      <i className="mdi mdi-delete-outline" />
                                                      <ReactTooltip
                                                        effect="solid"
                                                        id={
                                                          `del-conclusion-${index}` +
                                                          i
                                                        }
                                                      >
                                                        {`移除小總結內容`}
                                                      </ReactTooltip>
                                                    </button>
                                                  </Col>
                                                </Row>
                                              ) : null}
                                              <Row>
                                                <Col md={12}>
                                                  <SelectField
                                                    name={`SelfLearnSessionOptions.${index}.conclusions.${i}.type`}
                                                    label="選擇題類型"
                                                    placeholder="選擇題類型"
                                                    options={CONCLUSION_OPTIONS}
                                                  />
                                                </Col>
                                                {conc.type !== 'HTML' ? (
                                                  <>
                                                    <Col md={12}>
                                                      <InputField
                                                        name={`SelfLearnSessionOptions.${index}.conclusions.${i}.question`}
                                                        label={`題目`}
                                                        placeholder={'題目'}
                                                        type={'textarea'}
                                                      />
                                                    </Col>
                                                    <FieldArray
                                                      name={`SelfLearnSessionOptions.${index}.conclusions.${i}.answers`}
                                                      render={answerArrayHelpers => (
                                                        <>
                                                          {conc?.answers &&
                                                            conc?.answers.map(
                                                              (ans, key) => (
                                                                <div key={key}>
                                                                  <Row>
                                                                    <Col
                                                                      sm={11}
                                                                    >
                                                                      <InputField
                                                                        name={`SelfLearnSessionOptions.${index}.conclusions.${i}.answers.${key}`}
                                                                        label={`答案 ${
                                                                          key +
                                                                          1
                                                                        }`}
                                                                        placeholder={
                                                                          '答案'
                                                                        }
                                                                        type={
                                                                          'text'
                                                                        }
                                                                      />
                                                                    </Col>
                                                                    <Col
                                                                      sm={1}
                                                                      className="d-flex justify-content-end align-items-center"
                                                                    >
                                                                      <ul className="list-inline font-size-12 mb-0">
                                                                        <li className="list-inline-item me-0">
                                                                          <button
                                                                            className="custom-btn font-size-20"
                                                                            onClick={e => {
                                                                              e.preventDefault();
                                                                              answerArrayHelpers.remove(
                                                                                key,
                                                                              );
                                                                            }}
                                                                            data-tip
                                                                            disabled={
                                                                              // eslint-disable-next-line prettier/prettier
                                                                              conc.answers &&
                                                                              conc
                                                                                .answers
                                                                                .length <=
                                                                                1
                                                                            }
                                                                          >
                                                                            <i className="mdi mdi-delete-outline" />
                                                                          </button>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                          <button
                                                                            className="custom-btn font-size-22"
                                                                            onClick={e => {
                                                                              e.preventDefault();
                                                                              answerArrayHelpers.insert(
                                                                                key +
                                                                                  1,
                                                                                // eslint-disable-next-line prettier/prettier
                                                                                '',
                                                                              );
                                                                            }}
                                                                            data-tip
                                                                          >
                                                                            <i className="bx bx-plus" />
                                                                          </button>
                                                                        </li>
                                                                      </ul>
                                                                    </Col>
                                                                  </Row>
                                                                </div>
                                                              ),
                                                            )}
                                                        </>
                                                      )}
                                                    />
                                                  </>
                                                ) : null}
                                                <Col md={12}>
                                                  <HTMLField
                                                    name={`SelfLearnSessionOptions.${index}.conclusions.${i}.html`}
                                                    label="小總結"
                                                    theme="dark"
                                                  />
                                                </Col>
                                              </Row>
                                              {/* {index !==
                                                values.SelfLearnSessionOptions[
                                                  index
                                                ].conclusions.length -
                                                  1 && (
                                                <div
                                                  className="mt-4 mb-5"
                                                  style={{
                                                    borderBottom: 'dashed',
                                                    opacity: '0.2',
                                                  }}
                                                ></div>
                                              )} */}
                                            </div>
                                          ))}
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="ms-2 w-25"
                                          style={{ float: 'right' }}
                                          onClick={() =>
                                            arrayHelpers.push({
                                              html: '',
                                              question: '',
                                              type: 'MC' as ConclusionType,
                                              answers: [''],
                                            })
                                          }
                                        >
                                          新增小總結內容
                                        </Button>
                                      </>
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row className="d-flex justify-content-end mb-4">
                                <Col
                                  md={12}
                                  className="d-flex justify-content-end mt-2"
                                >
                                  {values.SelfLearnSessionOptions.length >=
                                  1 ? (
                                    <Button
                                      type="button"
                                      color="danger"
                                      className="w-25"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      移除選項
                                    </Button>
                                  ) : null}
                                </Col>
                                <Col md={12}>
                                  <div
                                    className="mt-4 mb-4"
                                    style={{
                                      borderBottom: 'dashed',
                                      opacity: '0.2',
                                    }}
                                  ></div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          {values.SelfLearnSessionOptions.length < 2 ? (
                            <Button
                              type="button"
                              color="primary"
                              className="ms-2 w-25"
                              style={{ float: 'right' }}
                              onClick={() =>
                                arrayHelpers.push({
                                  optionTitle: '',
                                  video: '',
                                  // color: '#000000',
                                  conclusions: [
                                    {
                                      html: '',
                                      question: '',
                                      type: 'MC' as ConclusionType,
                                      answers: [''],
                                    },
                                  ],
                                  isCorrect: true,
                                })
                              }
                            >
                              新增選項
                            </Button>
                          ) : null}
                        </>
                      )}
                    />
                  </Col>
                </Row>
                <div className="mt-3 d-flex flex-row-reverse">
                  <Button
                    type="submit"
                    color="primary"
                    className="ms-1"
                    disabled={loading}
                    onClick={e => {
                      e.preventDefault();
                      formRef.current?.handleSubmit();
                    }}
                  >
                    {!loading && formType !== 'view' ? (
                      '提交'
                    ) : (
                      <i className="bx bx-loader-circle bx-spin" />
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
};
