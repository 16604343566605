import { createSlice } from '@reduxjs/toolkit';
import { AdminRole } from 'app/models';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface PermissionState {}

const initialState = {} as PermissionState;

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
});

export default permissionSlice.reducer;

export const useAdminPermission = () => {
  const { auth } = useSelector((rootState: RootState) => rootState);

  const { profile } = auth;

  const hasPermission = (permissions: AdminRole[]): boolean => {
    let access = false;
    if (
      permissions &&
      permissions.length &&
      profile &&
      profile.role &&
      permissions.includes(profile?.role)
    ) {
      access = true;
    }

    return access;
  };

  return { hasPermission };
};
