import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { db } from 'app/services/FirebaseService';
import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  query,
  where,
  getDoc,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from 'firebase/firestore';
import { PAGE_SIZE } from 'app/config';
import { FirebaseError } from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { Admin, CustomChatRoom, RoomData, RoomUser } from 'app/models';
import { formatDisplayDate } from 'app/helpers/CommonHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import _ from 'lodash';
import * as Sentry from '@sentry/react';

interface InitChatRoomHookParams {
  type?: string;
}

export const useInitChatRoomList = ({ type }: InitChatRoomHookParams) => {
  const history = useHistory();
  const { profile } = useSelector((rootState: RootState) => rootState.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [rooms, setRoom] = useState<CustomChatRoom[]>([]);

  const handleChatRoomDataDocs = async (snap: QuerySnapshot<DocumentData>) => {
    try {
      setLoading(true);
      const formattedChatRooms: CustomChatRoom[] = await formatChatRooms(
        snap,
        profile,
      );
      setRoom(prevRooms => {
        if (prevRooms.length === 0) {
          return formattedChatRooms;
        } else {
          const mergedChatRoomList = _.uniqBy(
            formattedChatRooms.concat(prevRooms),
            'roomId',
          );
          return mergedChatRoomList;
        }
      });
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let unsubscribeChatRooms: any = null;

    const onLoadMessage = async (snap: QuerySnapshot<DocumentData>) => {
      handleChatRoomDataDocs(snap);
    };

    const onError = (error: FirebaseError) => {
      console.log(error);
      if (error?.code === 'permission-denied') {
        toast.warning('Firebase強制登出，請重新登入。');
        history.replace('/logout');
      } else {
        toast.warning('讀取對話房間時出現錯誤，請刷新頁面再重試。');
      }
    };

    if (type) {
      const q = query(
        collection(db, `rooms`),
        where('type', '==', type),
        orderBy('lastMessage.createdAt', 'desc'),
        limit(PAGE_SIZE),
      );
      unsubscribeChatRooms = onSnapshot<DocumentData>(
        q,
        onLoadMessage,
        onError,
      );
    }

    return () => {
      if (unsubscribeChatRooms) {
        unsubscribeChatRooms();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, history]);

  const loadMoreChatRoom = async (cb?: (lastRoomId: string) => void) => {
    if (type && !loading && rooms.length) {
      setLoading(true);
      const lastRoom = rooms[rooms.length - 1];
      const roomRef = doc(db, 'rooms', lastRoom.roomId);
      const lastRoomDocRef = await getDoc(roomRef);

      const q = query(
        collection(db, `rooms`),
        where('type', '==', type),
        orderBy('lastMessage.createdAt', 'desc'),
        startAfter(lastRoomDocRef),
        limit(PAGE_SIZE),
      );

      const snaps = await getDocs(q);
      const formattedChatRooms: CustomChatRoom[] = await formatChatRooms(
        snaps,
        profile,
      );

      setRoom(prevRooms => {
        return prevRooms.concat(formattedChatRooms);
      });

      if (cb) {
        cb(lastRoom.roomId);
      }
      setLoading(false);
    }
  };

  return {
    loading,
    rooms,
    loadMoreChatRoom,
  };
};

export const formatChatRooms = async (
  snap: QuerySnapshot<DocumentData>,
  profile?: Admin | null,
) => {
  let formattedChatRooms: CustomChatRoom[] = [];
  for (let i = 0; i < snap.docs.length; i++) {
    const docItem = snap.docs[i];
    const data = docItem.data() as RoomData;
    const tempData: CustomChatRoom = {
      roomId: docItem.ref.id,
      time: formatDisplayDate(data.lastMessage.createdAt.toDate()),
      lastMessageString: '',
      avatar: '',
      displayName: '',
      hasUnreadMessage: data?.lastMessage?.readBy?.[profile?.uid || '']
        ? false
        : true,
    };
    if (data.lastMessage.system) {
      if (data.lastMessage.system === 'chatInit') {
        tempData.lastMessageString = '開始對話';
      } else {
        tempData.lastMessageString = data.lastMessage.system;
      }
    } else if (data.lastMessage.text) {
      tempData.lastMessageString = data.lastMessage.text;
    } else if (data.lastMessage.image) {
      tempData.lastMessageString = '🖼️ 圖片';
    } else if (data.lastMessage.video) {
      tempData.lastMessageString = '🎥 影片';
    }
    if (data.members && data.members.length) {
      const memberArr = data.members.filter(member => member !== 'admin');
      if (memberArr.length) {
        const userRef = doc(db, 'users', memberArr[0]);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const user = userSnap.data() as RoomUser;
          tempData.avatar = user.avatar || '';
          tempData.displayName = user.displayName;
        }
        formattedChatRooms.push(tempData);
      }
    }
  }
  return formattedChatRooms;
};
