import { TestSubmissionData } from 'app/models';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import { UserBriefInfoCard } from '../User';

interface Props {
  data: TestSubmissionData;
}
export const CourseTestSubmissionInfoCard = (props: Props) => {
  const { data } = props;
  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-2 fs-4">已提交的測試資料</CardTitle>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                  <tr className="d-flex">
                    <th scope="row">提交編號 :</th>
                    <td>
                      <div>{data.selfLearnTestSubmissionId}</div>
                    </td>
                  </tr>
                  <tr className="d-flex">
                    <th scope="row">測試所得分數 :</th>
                    <td>
                      <div>{data.score}</div>
                    </td>
                  </tr>
                  <tr className="d-flex">
                    <th scope="row">測試後獲得積分 :</th>
                    <td>
                      <div>{data.pointEarnAfterCompletion}</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-3 fs-4">所填寫的課程測試</CardTitle>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                  <tr className="d-flex">
                    <th scope="row">課程測試表格編號 :</th>
                    <td>
                      <div>{data.selfLearnTestId}</div>
                    </td>
                  </tr>
                  <tr className="d-flex flex-column">
                    <td>
                      <div>
                        <div className="fw-bold mb-2">
                          {`測試概要 : ${data.SelfLearnTest.description}`}
                        </div>
                        {data.Answers.map((ans, index) => (
                          <Row key={index} className="mt-3">
                            <Col md={12} className="d-flex mt-1">
                              <div className=" me-2">{`題目${
                                index + 1
                              } : `}</div>
                              <div>{ans.questionTitle}</div>
                            </Col>
                            <Col md={12} className="d-flex mt-1">
                              <div className=" me-2">{'答案 : '}</div>
                              <div>{`${ans.ans} (${ans.score}分)`}</div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <UserBriefInfoCard user={data.User} />
    </Row>
  );
};
