import { OptionItems } from 'app/models';

export const QUESTION_TYPE: OptionItems[] = [
  {
    label: '選擇題',
    value: 'MC',
  },
  {
    label: '李克特量表',
    value: 'Likert Scale',
  },
];

export const CONCLUSION_OPTIONS: OptionItems[] = [
  {
    label: '多選題',
    value: 'MC',
  },
  {
    label: '單選題',
    value: 'SC',
  },
  {
    label: '沒有選擇題',
    value: 'HTML',
  },
];
