export const API_URL =
  process.env.REACT_APP_API_URL || 'https://menspace-app-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = "Men's Space Admin Web";
export const IMAGE_SIZE = 1024 * 10000;
export const VIDEO_SIZE = 1024 * 100000;
export const SWAGGER_URL = 'https://appicidea.com';

/* chat */
export const MESSAGE_PAGE_SIZE = 50;

// firebase
export const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  'AIzaSyDrwN0b0sy5SBgQs-tFylxur48evd1snv8';
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  'men-space-app-dev.firebaseapp.com';
export const FIREBASE_ID =
  process.env.REACT_APP_FIREBASE_ID || 'men-space-app-dev';
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_STORAGE || 'men-space-app-dev.appspot.com';
export const FIREBASE_APP_ID =
  process.env.REACT_APP_APP_ID || '1:673799438981:web:8f5aa526e13b3a5ca2dfec';

// versioning
export const VERSION_NUMBER = process.env.REACT_APP_VERSION || '';
export const CURRENT_ENV = process.env.REACT_APP_ENV || 'Dev';
