import { SidebarType } from 'app/models';
import React from 'react';
import SidebarContent from './SidebarContent';

interface Props {
  type: SidebarType;
}
const Sidebar = ({ type }: Props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
