import 'flatpickr/dist/themes/material_blue.css';
import { FieldHookConfig, useField } from 'formik';
import moment from 'moment';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { InputGroup, Label } from 'reactstrap';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';
import flatpickr from 'flatpickr';

flatpickr.localize(MandarinTraditional);

interface OtherProps {
  label: string;
  placeholder?: string;
  minDate?: Date | Date[] | null | 'today';
  onChange?: (value) => void;
  enableTimeInCAL?: boolean;
  disabled?: boolean;
  mode?: 'single' | 'range';
}

const DateField = (
  props: OtherProps & FieldHookConfig<(Date | null)[] | null>,
) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    minDate,
    onChange,
    enableTimeInCAL,
    disabled,
    mode,
  } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <InputGroup className={disabled ? 'disabled' : ''}>
        <Flatpickr
          className="form-control d-block bg-white"
          placeholder={placeholder || '日期'}
          options={{
            mode: mode ? mode : 'single',
            altInput: true,
            minDate,
            altFormat: enableTimeInCAL ? 'Y-m-d H:i' : 'Y-m-d',
            dateFormat: enableTimeInCAL ? 'Y-m-d H:i' : 'Y-m-d',
            enableTime: enableTimeInCAL ? true : false,
          }}
          value={field.value}
          onChange={date => {
            if (onChange) {
              onChange(date);
            } else {
              if (mode === 'range') {
                if (date && date.length === 2) {
                  helpers.setValue([
                    moment(date[0]).toDate(),
                    moment(date[1]).toDate(),
                  ]);
                } else {
                  helpers.setValue([moment(date[0]).toDate(), null]);
                }
              } else {
                if (date[0]) {
                  helpers.setValue(date);
                } else {
                  helpers.setValue(null);
                }
              }
            }
          }}
        />
      </InputGroup>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default DateField;
