import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import { Input, Label } from 'reactstrap';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
}

const InputField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const { label, type, placeholder, name, disabled } = props;
  return (
    <div
      className={`${type === 'textarea' ? 'mb-4' : 'mb-2'} form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
      style={type === 'textarea' ? { minHeight: 120 } : { height: 85 }}
    >
      {label ? <Label for={`${name}-input`}>{props.label}</Label> : null}
      <Input
        className="form-control"
        {...field}
        placeholder={placeholder}
        type={type}
        autoComplete="new-password"
        id={`${name}-input`}
        disabled={disabled}
        style={type === 'textarea' ? { minHeight: 120 } : {}}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default InputField;
