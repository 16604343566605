import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Col, Form, Row } from 'reactstrap';
import { SelectField } from '../Form';
import {
  CourseForm,
  CourseFormType,
} from 'app/pages/CoursePage/CourseDetailPage';
import { CourseData, OptionItems } from 'app/models';
import { updateCourse, getTestList } from 'app/services/CourseService';
import * as Sentry from '@sentry/react';

const Schema = Yup.object().shape({
  testId: Yup.string().nullable(),
});

type Form_Items = {
  testId: string;
};

const FORM_ITEM = {
  testId: '',
};

interface Props {
  courseId: number;
  testForm: CourseForm;
  testId: number;
  formType: CourseFormType;
  afterUpdate: () => void;
  courseData: CourseData;
}

export const CourseTestForm = (props: Props) => {
  const { testId, formType, afterUpdate, courseId, courseData, testForm } =
    props;
  const [loading, setLoading] = useState<boolean>(false);
  const [initLoad, setInitLoad] = useState<boolean>(false);
  const [form, setForm] = useState<Form_Items>(FORM_ITEM);
  const [testList, setTestList] = useState<OptionItems[]>([]);
  const formRef = useRef<FormikProps<Form_Items>>(null);

  const initForm = useCallback(async () => {
    try {
      const res = await getTestList({ limit: 99, offset: 0 });
      const tempList: OptionItems[] = [];
      res.rows.forEach(test =>
        tempList.push({
          label: test.description,
          value: test.selfLearnTestId.toString(),
        }),
      );
      setTestList(tempList);
      if (testId) {
        setForm({ testId: testId.toString() });
      }
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      setInitLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testId]);

  const updateTestData = async (testId: number) => {
    try {
      const tempCourse = { ...courseData };
      testForm === 'preTest'
        ? (tempCourse.preTestId = testId)
        : (tempCourse.postTestId = testId);
      await updateCourse(courseId, tempCourse);
      setLoading(false);
      toast.success('編輯測試成功。');
      afterUpdate();
    } catch (err) {
      setLoading(false);
      toast.warning('編輯測試失敗，請重試。');
      Sentry.captureException(err);
      console.log(err);
    }
  };

  const onSubmit = async (
    values: Form_Items,
    actions: FormikHelpers<Form_Items>,
  ) => {
    setLoading(true);
    const { testId } = values;
    updateTestData(Number(testId));
  };

  useEffect(() => {
    initForm();
  }, [initForm]);

  return (
    <>
      {initLoad ? (
        <div className="p-2">
          <Formik
            initialValues={form}
            validationSchema={Schema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ values }) => (
              <Form className="form-horizontal">
                <Row>
                  <Col md={12}>
                    <SelectField
                      name="testId"
                      label="課程問卷"
                      placeholder="課程問卷"
                      options={testList}
                    />
                  </Col>
                </Row>
                <div className="mt-3 d-flex flex-row-reverse">
                  <Button
                    type="submit"
                    color="primary"
                    className="ms-1"
                    disabled={loading}
                    onClick={e => {
                      e.preventDefault();
                      formRef.current?.handleSubmit();
                    }}
                  >
                    {!loading && formType !== 'view' ? (
                      '提交'
                    ) : (
                      <i className="bx bx-loader-circle bx-spin" />
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
};
