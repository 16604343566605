import { User } from 'app/models';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import { AvatarProps, UserAvatar } from './UserAvatar';
import { Link } from 'react-router-dom';
interface Props extends AvatarProps {
  user?: User | null;
  display?: 'vertical' | 'horizontal';
}
export const UserBriefInfoCard = (props: Props) => {
  const { user, size, imageSize, display = 'horizontal' } = props;

  const isVertical = display === 'vertical';
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <CardTitle className="mb-2 fs-4">用戶資料</CardTitle>
          </Row>
          <Row>
            <Col sm={12} md={isVertical ? 12 : 6} lg={isVertical ? 12 : 6}>
              <div
                className={`user-info d-flex ${
                  isVertical
                    ? 'flex-column  align-items-start'
                    : 'flex-row  align-items-center'
                } justify-content-start`}
              >
                <UserAvatar
                  size={size ? size : 'lg'}
                  avatar={user?.avatar}
                  imageSize={imageSize || 320}
                />
                <div className={`table-responsive ${isVertical ? '' : 'ms-2'}`}>
                  <Table className="table-nowrap mb-0 custom-table">
                    <tbody>
                      <tr>
                        <th scope="row">名稱 :</th>
                        <td>
                          {user?.userId ? (
                            <Link
                              to={`/users/view/${user.userId}`}
                              target="_blank"
                              className="text-primary"
                            >
                              {user.displayName}
                            </Link>
                          ) : (
                            <div>{user?.displayName || ''}</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">編號 :</th>
                        <td>
                          <div>{user?.userId || '-'}</div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">性別 :</th>
                        <td>
                          <div>{user?.gender || '-'}</div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">年齡 :</th>
                        <td>
                          <div>{user?.age || '-'}</div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col sm={12} md={isVertical ? 12 : 6} lg={isVertical ? 12 : 6}>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0 d-flex custom-table">
                  <tbody>
                    <tr className="d-flex">
                      <th scope="row">電子郵件 :</th>
                      <td>
                        <div>{user?.email || '-'}</div>
                      </td>
                    </tr>
                    <tr className="d-flex">
                      <th scope="row">電話號碼 :</th>
                      <td>
                        <div>{`${user?.countryCallingCode} ${
                          user?.contactPhone || '-'
                        }`}</div>
                      </td>
                    </tr>
                    <tr className="d-flex">
                      <th scope="row">是否接收消息 :</th>
                      <td>{user?.isReceiveNotification ? '是' : '否'}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
