import Breadcrumbs from 'app/components/Common/Breadcrumb';
import TableCount from 'app/components/Common/TableCount';
import Dialog from 'app/components/Dialog';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME } from 'app/config';
import { useAutoComplete, useTableData } from 'app/hooks';
import { EventApplicationData, EventData } from 'app/models';
import {
  deleteEventApplication,
  getEventApplicationList,
  GetEventApplicationListParams,
  getEventList,
  GetEventListParams,
} from 'app/services/EventService';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import EventApplicationListColumns from './EventApplicationListColumns';
import { toast } from 'react-toastify';
import { EventApplicationListForm } from 'app/components/Event';
import { EVENT_APPLICATION_OPTIONS } from 'app/const';
import * as Sentry from '@sentry/react';

export const EventApplicationListPage = () => {
  const {
    dataList: applicationList,
    count,
    page,
    getDataList,
  } = useTableData<GetEventApplicationListParams, EventApplicationData>({
    call: getEventApplicationList,
    filterVariableArray: [
      'asc',
      'desc',
      'startDate',
      'endDate',
      'status',
      'courseId',
    ],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<number>(0);
  const [applicationData, setApplicationData] =
    useState<EventApplicationData>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const onDeleteButtonClick = (id: number) => {
    setApplicationId(id);
    setDeleteVisible(true);
  };

  const deleteConfirm = async () => {
    if (applicationId) {
      setLoading(true);
      try {
        await deleteEventApplication(applicationId);
        toast.success('成功刪除活動申請。');
        setLoading(false);
        setDeleteVisible(false);
        getDataList();
      } catch (err) {
        setLoading(false);
        toast.error('刪除活動申請失敗，請重試。');
        Sentry.captureException(err);
        setDeleteVisible(false);
      }
    }
  };

  const onEditButtonClick = (data: EventApplicationData) => {
    setApplicationData(data);
    setDialogOpen(true);
  };

  // event
  const {
    // searchText: userText,
    setSearchText: setEventText,
    dataList: eventList,
    onMenuScrollToBottom: onEventScrollTouchBottom,
  } = useAutoComplete<GetEventListParams, EventData>({
    getListCall: getEventList,
    labelRender: (event: EventData) => ({
      label: `#${event.courseId} | ${event.title}`,
      value: event.courseId.toString(),
    }),
    keyExtracter: (event: EventData) => event.courseId,
    searchTextFieldName: 'title',
  });

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>活動申請列表 | {APP_NAME}</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs title="活動申請" breadcrumbItem="活動申請列表" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col
                        sm={12}
                        className="d-flex align-self-end justify-content-end"
                      >
                        <TableCount count={count} title="活動申請總數" />
                      </Col>
                      <Col sm={4}>
                        <AutoCompleteSelect
                          name="courseId"
                          label="搜尋活動"
                          placeholder="活動名稱"
                          onInputChange={setEventText}
                          options={eventList}
                          onMenuScrollToBottom={onEventScrollTouchBottom}
                          isClearable
                        />
                      </Col>
                      <Col sm={4}>
                        <DatePickerFilter
                          name="startDate"
                          name2="endDate"
                          label="活動日期"
                          placeholder="活動日期"
                          isClearable
                          mode="range"
                        />
                      </Col>
                      <Col sm={4}>
                        <SelectFilter
                          label="申請狀態"
                          placeholder="申請狀態"
                          name="status"
                          options={EVENT_APPLICATION_OPTIONS}
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={EventApplicationListColumns(
                              onDeleteButtonClick,
                              onEditButtonClick,
                            )}
                            keyField="applicationId"
                            data={applicationList}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Dialog
        visible={deleteVisible}
        title="刪除活動申請"
        onClose={() => setDeleteVisible(false)}
        loading={loading}
        onConfirm={deleteConfirm}
      >
        <div>{`您確定要刪除該活動申請嗎？`}</div>
      </Dialog>
      {applicationData ? (
        <Dialog
          visible={dialogOpen}
          title={'編輯活動申請'}
          onClose={() => setDialogOpen(false)}
        >
          <EventApplicationListForm
            afterUpdate={() => {
              getDataList();
              setDialogOpen(false);
            }}
            data={applicationData}
          />
        </Dialog>
      ) : null}
    </>
  );
};
