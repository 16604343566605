import { FieldArrayRenderProps } from 'formik';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import InputField from './InputField';

interface Props {
  arrayHelpers: FieldArrayRenderProps;
  parentName: string;
  currentIndex: number;
  values: any;
}

export const AnswerOptionRow = (props: Props) => {
  const { arrayHelpers, parentName, currentIndex, values } = props;
  return (
    <Row>
      <Col sm={9}>
        <InputField
          name={`${parentName}.${currentIndex}.ans`}
          label={`答案 ${currentIndex + 1}`}
          placeholder={'答案'}
          type={'text'}
        />
      </Col>
      <Col sm={2}>
        <InputField
          name={`${parentName}.${currentIndex}.score`}
          label={`積分`}
          placeholder={'積分'}
          type={'number'}
        />
      </Col>
      <Col sm={1} className="d-flex justify-content-end align-items-center">
        <ul className="list-inline font-size-12 mb-0">
          <li className="list-inline-item me-0">
            <button
              type="button"
              className="custom-btn font-size-20"
              onClick={() => arrayHelpers.remove(currentIndex)}
              data-tip
              disabled={_.get(values, parentName).length <= 4}
            >
              <i className="mdi mdi-delete-outline" />
            </button>
          </li>
          <li className="list-inline-item">
            <button
              type="button"
              className="custom-btn font-size-22"
              onClick={() =>
                arrayHelpers.insert(currentIndex + 1, {
                  ans: '',
                  score: 0,
                })
              }
              data-tip
              disabled={_.get(values, parentName).length >= 6}
            >
              <i className="bx bx-plus" />
            </button>
          </li>
        </ul>
      </Col>
    </Row>
  );
};
