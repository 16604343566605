/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import NonAuthLayout from 'app/components/NonAuthLayout';
// layouts Format
import VerticalLayout from 'app/components/VerticalLayout';
import { APP_NAME, TOAST_TIME } from 'app/config';
import { authRoutes, userRoutes } from 'app/route/allRoutes';
import ProtectedRoute from 'app/route/ProtectedRoute';
import { AxiosInterceptor } from 'app/services';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from 'styles/global-styles';
import { getIdToken, onAuthStateChanged } from 'firebase/auth';
import { auth } from './services/FirebaseService';
import { useBotChatListener } from './hooks/useBackgroundEvents';

export function App() {
  useBotChatListener();
  useEffect(() => {
    onAuthStateChanged(auth, async user => {
      if (user) {
        console.log('refresh firebase token');
        await getIdToken(user);
      }
    });
  }, []);

  function getLayout() {
    return VerticalLayout;
  }
  const Layout = getLayout();
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>{APP_NAME}</title>
          <meta name="description" content="Admin Panel" />
        </Helmet>
        <AxiosInterceptor>
          <Switch>
            {authRoutes.map((route, idx) => (
              <ProtectedRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact={route.exact}
              />
            ))}
            {userRoutes.map((route, idx) => (
              <ProtectedRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
                permission={route.permission}
              />
            ))}
            <Redirect from="*" to="/notFound" />
          </Switch>
        </AxiosInterceptor>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />
      </BrowserRouter>
    </HelmetProvider>
  );
}
