import { APP_NAME } from 'app/config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';

const PrivacyPolicyPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>使用條款及私隱權條款 | {APP_NAME}</title>
        </Helmet>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>使用條款</h4>
                          </div>
                          <div className="mt-4">
                            <h5>版權</h5>
                            <div className="text-muted font-size-14">
                              <p>請尊重知識版權。</p>

                              <p>
                                香港心理衞生會擁有本應用程式的一切版權，本程式的內容及資料只作用戶觀看及參考，不可作其他用途。
                              </p>
                            </div>

                            <h5>數據用量</h5>
                            <div className="text-muted font-size-14">
                              <p>
                                用戶請留意個人手機的流動數據服務之計算方法，及定期檢查數據用量，以免數據使用過量。
                              </p>
                            </div>

                            <h5>私隱政策聲明</h5>
                            <div className="text-muted font-size-14">
                              <p>
                                本會尊重個人資料私隱權，備有確保服務使用者的保密權得到尊重的政策及程序，並且承諾遵守
                                <a
                                  style={{ fontWeight: 700, color: '#556ee6' }}
                                  href="http://www.pcpd.org.hk/tc_chi/data_privacy_law/ordinance_at_a_Glance/ordinance.html"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  《個人資料（私隱）條例》
                                </a>
                                的有關規定。
                              </p>
                            </div>

                            <h5>資料收集與使用</h5>
                            <div className="text-muted font-size-14">
                              <ol>
                                <li>
                                  <p>
                                    除了「線上輔導」服務之外，本程式只會記錄用戶的手機版本和型號、手機名稱及帳號驗證憑證(Device
                                    Token)，以及使用和瀏覽的次數，但並不會收集足以辨識使用者身份的資料。所收集的記錄僅用於製作統計報告及調查電腦系統問題，以助改善本程式的服務。
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    為了方便用戶聯絡可求助的機構，本程式提供捷徑讓用戶撥打查詢電話，但並不會讀取用戶手機通訊錄上的任何資料。
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    本程式支援透過第三者服務供應商的「分享」資料功能。倘若用戶使用該功能，請參閱個別服務供應商的私隱政策。
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    用戶於本程式之「線上輔導」，與輔導員所談及的個人私隱，本會絕不會以任何形式出售、租借及轉讓予任何人士或組織。
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    本會將確保將所有收集的資料保密，只有獲得授權的人士才可查閱。除法律規定或徵得相關人士同意外，本會不能向其他人士披露可辨識個人身份的資料。
                                  </p>
                                </li>
                              </ol>
                            </div>

                            <div className="text-muted font-size-14">
                              <p>
                                任何情況下，捐助機構皆不會對任何人或法律實體因此載內容而作出或沒有作出的任何行為負上任何法律責任。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
