import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changePreloader } from 'store/Layout';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import { Formik, Form, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { InputField, SelectField } from 'app/components/Form';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { APP_NAME } from 'app/config';
import {
  createTest,
  deleteTest,
  getTestData,
  updateTest,
} from 'app/services/CourseService';
import { Answer, FormType, TestQuestion } from 'app/models';
import Dialog from 'app/components/Dialog';
import { QUESTION_TYPE } from 'app/const';
import { AnswerOptionRow } from 'app/components/Form/AnswerOptionRow';
import { ScrollToError } from 'app/components/Course';
import * as Sentry from '@sentry/react';

const Schema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .min(2, '最少要2個字元')
    .max(50, '最多50個字元')
    .required('必填'),
  SelfLearnTestQuestions: Yup.array().of(
    Yup.object().shape({
      part: Yup.string()
        .nullable()
        .min(2, '最少要2個字元')
        .max(200, '最多200個字元')
        .required('必填'),
      question: Yup.array().of(
        Yup.object().shape({
          questionTitle: Yup.string()
            .nullable()
            .min(2, '最少要2個字元')
            .required('必填'),
          questionCode: Yup.string()
            .nullable()
            .min(1, '最少要1個字元')
            .max(20, '最多20個字元')
            .required('必填'),
          type: Yup.string().nullable().required('必填'),
          Answers: Yup.array().of(
            Yup.object().shape({
              ans: Yup.string()
                .nullable()
                .min(1, '最少要1個字元')
                .required('必填'),
              score: Yup.number()
                .nullable()
                .min(0, '請輸入正數')
                .required('必填'),
            }),
          ),
        }),
      ),
    }),
  ),
});

type FormItem = {
  formType: FormType;
  description: string;
  SelfLearnTestQuestions: TempQuestions[];
};

type TempQuestions = {
  part: string;
  question: {
    questionTitle: string;
    ordering: number;
    Answers: Answer[];
    questionCode: string;
    type: string;
  }[];
};

const QUESTION_ITEM = {
  questionCode: '',
  questionTitle: '',
  ordering: 0,
  type: 'MC',
  Answers: [
    {
      ans: '',
      score: 0,
    },
    {
      ans: '',
      score: 0,
    },
    {
      ans: '',
      score: 0,
    },
    {
      ans: '',
      score: 0,
    },
    {
      ans: '',
      score: 0,
    },
  ],
};

const QUESTION_PART_ITEM = {
  part: '',
  question: [QUESTION_ITEM],
};

const FORM_ITEM = {
  formType: 'create' as FormType,
  description: '',
  SelfLearnTestQuestions: [QUESTION_PART_ITEM],
};

interface CourseTestFormParams {
  selfLearnTestid: string;
}

export const CourseTestFormPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<CourseTestFormParams>();

  const [courseTestFormId, setTestId] = useState<number | null>(null);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { selfLearnTestid } = params;
    const query = new URLSearchParams(location.search);
    const testId = query.get('testId');
    try {
      if (selfLearnTestid) {
        const res = await getTestData(Number(selfLearnTestid));
        const { selfLearnTestId } = res;
        setTestId(selfLearnTestId);
        const { description, SelfLearnTestQuestions } = res;
        const tempQuestions: TempQuestions[] = Object.values(
          SelfLearnTestQuestions.reduce((item, next) => {
            if (!item[next.part]) {
              item[next.part] = {
                part: next.part,
                question: [],
              };
            }

            item[next.part].question.push(next);
            return item;
          }, {}),
        );
        setForm({
          description,
          SelfLearnTestQuestions: tempQuestions,
          formType: 'edit',
        });
      } else if (testId) {
        const res = await getTestData(Number(testId));
        const { description, SelfLearnTestQuestions } = res;
        const tempQuestions: TempQuestions[] = Object.values(
          SelfLearnTestQuestions.reduce((item, next) => {
            if (!item[next.part]) {
              item[next.part] = {
                part: next.part,
                question: [],
              };
            }

            item[next.part].question.push(next);
            return item;
          }, {}),
        );
        setForm({
          description,
          SelfLearnTestQuestions: tempQuestions,
          formType: 'create',
        });
      }
    } catch (err) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      dispatch(changePreloader(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const createNewTest = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    try {
      const params = await formatData(values);
      await createTest(params);
      toast.success('新增課程測試成功。');
      history.push('/course-tests');
    } catch (err) {
      setLoading(false);
      toast.warning('新增課程測試失敗，請重試。');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  const updateTestData = async (values: FormItem) => {
    setLoading(true);
    try {
      const params = await formatData(values);
      await updateTest(courseTestFormId!, params);
      setLoading(false);
      toast.success('編輯課程測試成功。');
    } catch (err) {
      setLoading(false);
      toast.warning('編輯課程測試失敗，請重試。');
      Sentry.captureException(err);
      console.log(err);
    }
  };

  const formatData = async (values: FormItem) => {
    const { description, SelfLearnTestQuestions } = values;
    const tempQuestions: TestQuestion[] = [];
    let order = 0;
    SelfLearnTestQuestions.forEach(ques => {
      ques.question.forEach(val => {
        tempQuestions.push({ ...val, part: ques.part, ordering: order });
        order++;
      });
    });
    const params = { description, SelfLearnTestQuestions: tempQuestions };
    return params;
  };

  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    if (values.formType === 'create') {
      createNewTest(values, actions);
    } else {
      updateTestData(values);
    }
  };

  const deleteConfirm = async () => {
    setLoading(false);
    try {
      await deleteTest(courseTestFormId!);
      toast.success('成功刪除課程測試');
      setLoading(false);
      setDeleteVisible(false);
      history.push('/course-tests');
    } catch (err) {
      toast.warning('刪除課程測試失敗，請重試。');
      setLoading(false);
      setDeleteVisible(false);
      Sentry.captureException(err);
    }
  };

  const TITLE = `${`${courseTestFormId ? '編輯' : '新增'}`}課程測試`;

  const BreadcrumbsOptions = [
    { title: '課程', path: '/course-tests' },
    { title: '課程測試列表', path: '/course-tests' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validateOnMount={false}
                      >
                        {({
                          values,
                          errors,
                          isValid,
                          submitCount,
                          isValidating,
                          isSubmitting,
                        }) => (
                          <Form className="form-horizontal">
                            <ScrollToError
                              submitCount={submitCount}
                              isValid={isValid}
                              isValidating={isValidating}
                              isSubmitting={isSubmitting}
                            />
                            <Row>
                              <Col md={12}>
                                <InputField
                                  name={'description'}
                                  label={'測試標題'}
                                  placeholder={'測試標題'}
                                  type={'text'}
                                />
                              </Col>
                              <Col md={12}>
                                <FieldArray
                                  name="SelfLearnTestQuestions"
                                  render={arrayHelpers => (
                                    <>
                                      {values.SelfLearnTestQuestions.map(
                                        (question, i) => (
                                          <div key={i}>
                                            <Row>
                                              <Col md={12} className="mt-5">
                                                <CardTitle>
                                                  <h5>題目 {i + 1}</h5>
                                                </CardTitle>
                                                <hr />
                                              </Col>
                                              <Col sm={12}>
                                                <InputField
                                                  name={`SelfLearnTestQuestions.${i}.part`}
                                                  label={`題目標題`}
                                                  placeholder={'題目標題'}
                                                  type={'textarea'}
                                                />
                                              </Col>
                                              <Col sm={12}>
                                                <FieldArray
                                                  name={`SelfLearnTestQuestions.${i}.question`}
                                                  render={arrayHelpers => (
                                                    <>
                                                      {question?.question?.map(
                                                        (ques, index) => (
                                                          <div key={index}>
                                                            <Row className="mt-2">
                                                              <Col
                                                                md={12}
                                                                className="mt-2 mb-3"
                                                              >
                                                                <h6>
                                                                  題目 {i + 1}
                                                                  {
                                                                    ' - '
                                                                  }問題{' '}
                                                                  {index + 1}
                                                                </h6>
                                                              </Col>
                                                              <Col md={6}>
                                                                <InputField
                                                                  name={`SelfLearnTestQuestions.${i}.question.${index}.questionCode`}
                                                                  label={`題目題號`}
                                                                  placeholder={
                                                                    '例: a/1'
                                                                  }
                                                                  type={'text'}
                                                                />
                                                              </Col>
                                                              <Col md={6}>
                                                                <SelectField
                                                                  name={`SelfLearnTestQuestions.${i}.question.${index}.type`}
                                                                  label="題目類型"
                                                                  placeholder="申請狀態"
                                                                  options={
                                                                    QUESTION_TYPE
                                                                  }
                                                                />
                                                              </Col>
                                                              <Col md={12}>
                                                                <InputField
                                                                  name={`SelfLearnTestQuestions.${i}.question.${index}.questionTitle`}
                                                                  label={`題目描述`}
                                                                  placeholder={
                                                                    '描述'
                                                                  }
                                                                  type={'text'}
                                                                />
                                                              </Col>
                                                            </Row>
                                                            <FieldArray
                                                              name={`SelfLearnTestQuestions.${i}.question.${index}.Answers`}
                                                              render={arrayHelpers => (
                                                                <>
                                                                  {ques.Answers.map(
                                                                    (
                                                                      ans,
                                                                      key,
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          key
                                                                        }
                                                                      >
                                                                        <AnswerOptionRow
                                                                          arrayHelpers={
                                                                            arrayHelpers
                                                                          }
                                                                          parentName={`SelfLearnTestQuestions.${i}.question.${index}.Answers`}
                                                                          currentIndex={
                                                                            key
                                                                          }
                                                                          values={
                                                                            values
                                                                          }
                                                                        />
                                                                      </div>
                                                                    ),
                                                                  )}
                                                                </>
                                                              )}
                                                            />
                                                            <Row className="d-flex justify-content-end mb-4">
                                                              <Col
                                                                md={12}
                                                                className="d-flex justify-content-end"
                                                              >
                                                                <Button
                                                                  type="button"
                                                                  color="primary"
                                                                  className={`me-2 w-10`}
                                                                  style={{
                                                                    float:
                                                                      'right',
                                                                  }}
                                                                  onClick={() =>
                                                                    arrayHelpers.insert(
                                                                      index + 1,
                                                                      ques,
                                                                    )
                                                                  }
                                                                >
                                                                  複製問題
                                                                </Button>
                                                                {values
                                                                  .SelfLearnTestQuestions[
                                                                  i
                                                                ].question
                                                                  .length >
                                                                1 ? (
                                                                  <Button
                                                                    type="button"
                                                                    color="danger"
                                                                    className="w-10"
                                                                    onClick={() =>
                                                                      arrayHelpers.remove(
                                                                        index,
                                                                      )
                                                                    }
                                                                  >
                                                                    移除問題
                                                                  </Button>
                                                                ) : null}
                                                              </Col>
                                                            </Row>
                                                            {
                                                              <div
                                                                className="mt-4 mb-4"
                                                                style={{
                                                                  borderBottom:
                                                                    'dashed',
                                                                  opacity:
                                                                    '0.2',
                                                                }}
                                                              ></div>
                                                            }
                                                          </div>
                                                        ),
                                                      )}
                                                      <Col sm={12}>
                                                        <Button
                                                          type="button"
                                                          color="primary"
                                                          className={`ms-2 w-25 mb-2`}
                                                          style={{
                                                            float: 'right',
                                                          }}
                                                          onClick={() =>
                                                            arrayHelpers.push(
                                                              QUESTION_ITEM,
                                                            )
                                                          }
                                                        >
                                                          新增問題
                                                        </Button>
                                                      </Col>
                                                    </>
                                                  )}
                                                />
                                              </Col>
                                            </Row>
                                            <Row sm={12}>
                                              <Col
                                                md={12}
                                                className={`d-flex justify-content-end ${
                                                  values.SelfLearnTestQuestions
                                                    .length &&
                                                  values.SelfLearnTestQuestions
                                                    .length -
                                                    1 ===
                                                    i
                                                    ? 'mb-2'
                                                    : 'mb-5'
                                                }`}
                                              >
                                                {values.SelfLearnTestQuestions
                                                  .length > 1 ? (
                                                  <Button
                                                    type="button"
                                                    color="danger"
                                                    className="w-25"
                                                    onClick={() =>
                                                      arrayHelpers.remove(i)
                                                    }
                                                  >
                                                    移除題目
                                                  </Button>
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </div>
                                        ),
                                      )}
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="ms-2 w-25 mt-4"
                                        style={{ float: 'right' }}
                                        onClick={() =>
                                          arrayHelpers.push(QUESTION_PART_ITEM)
                                        }
                                      >
                                        新增題目
                                      </Button>
                                    </>
                                  )}
                                />
                              </Col>
                            </Row>
                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {!loading ? (
                                  '提交'
                                ) : (
                                  <i className="bx bx-loader-circle bx-spin" />
                                )}
                              </Button>
                              {courseTestFormId &&
                              values.formType === 'edit' ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Dialog
        visible={deleteVisible}
        title={`刪除課程測試`}
        onClose={() => setDeleteVisible(false)}
        loading={loading}
        onConfirm={deleteConfirm}
      >
        <div>{`您確定要刪除該課程測試嗎？`}</div>
      </Dialog>
    </>
  );
};
