import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { ImageField, InputField } from '../Form';
import { Button } from 'reactstrap';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store';
import * as Yup from 'yup';
import { getImageURL } from 'app/helpers/CommonHelper';
import { uploadImage } from 'app/services/CommonService';
import { getProfile, updateProfile } from 'app/services/AdminServices';
import { useDispatch } from 'react-redux';
import { setProfile } from 'store/Auth';
import * as Sentry from '@sentry/react';

const Schema = Yup.object().shape({
  displayName: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元'),
  coverImage: Yup.mixed().nullable(),
});

type FormItem = {
  displayName: string;
  avatar: any;
};
const FORM_ITEM: FormItem = {
  displayName: '',
  avatar: '',
};

interface Props {
  onClose: () => void;
}

export const AdminProfileForm = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);
  // const { profile } = useSelector((rootState: RootState) => rootState.auth);

  const initForm = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getProfile();
      const { displayName, avatar } = res;
      setForm({
        displayName,
        avatar: avatar
          ? [
              {
                preview: getImageURL(avatar, 768),
                avatar: avatar,
                name: '當前圖像',
              },
            ]
          : '',
      });
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const formatData = async (values: FormItem) => {
    const { displayName, avatar } = values;
    const params = {
      displayName,
      avatar,
    };
    if (avatar && avatar?.[0]?.size) {
      const formData = new FormData();
      formData.append('file', avatar[0]);
      const imageRes = await uploadImage(formData);
      if (imageRes) {
        params.avatar = imageRes.fileName;
      }
    } else if (avatar && avatar?.[0]?.avatar) {
      params.avatar = avatar?.[0]?.avatar;
    }
    return params;
  };

  const updateAdminProfile = async (values: FormItem) => {
    try {
      const params = await formatData(values);
      const res = await updateProfile(params);
      dispatch(setProfile(res));
      toast.success('更改資料成功');
      onClose();
    } catch (err) {
      toast.error('更改資料失敗，請重試。');
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    updateAdminProfile(values);
  };

  return (
    <>
      <Formik
        validationSchema={Schema}
        initialValues={form}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Row>
              <Col xs={12}>
                <InputField
                  name="displayName"
                  label="管理員名稱"
                  placeholder="管理員名稱"
                  type="text"
                />
              </Col>
              <Col xs={12}>
                <ImageField
                  className="mb-4"
                  name="avatar"
                  label="頭像 (長寬比 1:1)"
                />
              </Col>
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="primary"
                  className="ms-1"
                  disabled={Loading}
                >
                  {Loading ? (
                    <i className="bx bx-loader-circle bx-spin" />
                  ) : (
                    '提交'
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
