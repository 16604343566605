import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import Dialog from 'app/components/Dialog';
import {
  AutoCompleteField,
  DateField,
  ImageField,
  InputField,
  SelectField,
} from 'app/components/Form';
import { APP_NAME } from 'app/config';
import { BANNER_TYPE_OPTIONS } from 'app/const';
import { getImageURL } from 'app/helpers/CommonHelper';
import { useAutoComplete } from 'app/hooks';
import { BannerType, CourseData, EventData, PreviewFile } from 'app/models';
import {
  CreateOneBannerParams,
  createOneBanner,
  deleteOneBanner,
  getBannerList,
  updateOneBanner,
} from 'app/services/BannerServices';
import { uploadImage } from 'app/services/CommonService';
import { GetCourseListParams, getCourseList } from 'app/services/CourseService';
import { GetEventListParams, getEventList } from 'app/services/EventService';

import { Form, Formik } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import * as Yup from 'yup';

export type BannerFormItem = {
  image: File[] | PreviewFile[] | null;
  title: string;
  selfLearnCourseId: string | null;
  courseId: string | null;
  publishedDate: string | Date[];
  isPublishNow: string;
  bannerType: string;
  bannerId?: string | null;
};

const FORM_ITEM = {
  image: null,
  title: '',
  selfLearnCourseId: null,
  courseId: null,
  publishedDate: '',
  isPublishNow: 'true',
  bannerType: 'HomePage',
  bannerId: null,
};

const INFOS_SCHEMA = Yup.object({
  title: Yup.string().required('彈出海報名稱為必填'),
  publishedDate: Yup.date().when('isPublishNow', {
    is: 'false',
    then: Yup.date().required('發佈時間為必填'),
  }),
  image: Yup.mixed().required('圖片為必填'),
  courseId: Yup.string()
    .nullable()
    .when('bannerType', {
      is: 'Course',
      then: Yup.string().nullable().required('活動名稱為必選'),
    }),
  selfLearnCourseId: Yup.string()
    .nullable()
    .when('bannerType', {
      is: 'SelfLearnCourse',
      then: Yup.string().nullable().required('課程名稱為必選'),
    }),
});

export const PopupBannerFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const [form, setForm] = useState<BannerFormItem>({
    ...FORM_ITEM,
  });
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const getFormData = useCallback(async () => {
    try {
      dispatch(changePreloader(true));
      const res = await getBannerList({
        limit: 1,
        offset: 0,
        hasVerticalImage: true,
      });

      if (res.count) {
        const {
          title,
          selfLearnCourseId,
          courseId,
          publishedDate,
          verticalImage,
          bannerId,
          type,
        } = res.rows[0];
        const isPublishNow = moment(publishedDate).isBefore(moment())
          ? 'true'
          : 'false';
        const form = {
          image: verticalImage
            ? [
                {
                  preview: getImageURL(verticalImage, 768),
                  image: verticalImage,
                  name: '當前圖像',
                },
              ]
            : null,
          title,
          selfLearnCourseId: selfLearnCourseId?.toString() || null,
          courseId: courseId?.toString() || null,
          publishedDate: moment(publishedDate).format(),
          isPublishNow,
          bannerType: type,
          bannerId: bannerId?.toString() || null,
        };
        setForm(form);
      }
    } catch (err: any) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
    } finally {
      dispatch(changePreloader(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  const onSubmit = async (values: BannerFormItem) => {
    setLoading(true);
    try {
      const {
        image,
        title,
        selfLearnCourseId,
        courseId,
        bannerType,
        publishedDate,
        bannerId,
      } = values;
      let tempImage = '';
      if (image && image[0] && 'size' in image[0]) {
        const formData = new FormData();
        formData.append('file', image[0]);
        const uploadRes = await uploadImage(formData);
        tempImage = uploadRes.fileName;
      } else if (image && image[0] && 'image' in image[0]) {
        tempImage = image[0]?.image;
      }
      const bannerData: CreateOneBannerParams = {
        images: [tempImage],
        verticalImage: tempImage,
        title,
        selfLearnCourseId:
          bannerType === 'SelfLearnCourse' ? selfLearnCourseId : null,
        courseId: bannerType === 'Course' ? courseId : null,
        type: bannerType as BannerType,
        publishedDate:
          values.isPublishNow === 'true'
            ? moment().format()
            : moment(publishedDate[0]).format(),
      };
      if (bannerId) {
        await updateOneBanner(bannerId, bannerData);
        toast.success('編輯彈出海報成功。');
      } else {
        await createOneBanner(bannerData);
        toast.success('新增彈出海報成功。');
      }
      getFormData();
    } catch (err) {
      toast.error(`${form.bannerId ? '編輯' : '新增'}彈出海報失敗，請再試。`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteData = useCallback(async () => {
    if (form.bannerId) {
      try {
        setLoading(true);
        await deleteOneBanner(form.bannerId);
        setForm(FORM_ITEM);
        getFormData();
        toast.success('刪除成功。');
        setDeleteVisible?.(false);
      } catch (err) {
        toast.error('刪除失敗，請重試。');
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warning('請檢查你的網絡。');
    }
  }, [form.bannerId, getFormData]);

  const {
    searchText: event,
    setSearchText: setEventText,
    dataList: eventList,
    onMenuScrollToBottom,
  } = useAutoComplete<GetEventListParams, EventData>({
    getListCall: getEventList,
    labelRender: (event: EventData) => ({
      label: `${event.title}`,
      value: event.courseId.toString(),
    }),
    keyExtracter: (event: EventData) => `event-${event.courseId}`,
    searchTextFieldName: 'title',
  });

  const {
    searchText: selfLearnCourse,
    setSearchText: setSelfLearnCourseText,
    dataList: selfLearnList,
    onMenuScrollToBottom: onMenuSelfLearnScrollToBottom,
  } = useAutoComplete<GetCourseListParams, CourseData>({
    getListCall: getCourseList,
    labelRender: (course: CourseData) => ({
      label: `${course.title}`,
      value: course.selfLearnCourseId.toString(),
    }),
    keyExtracter: (course: CourseData) => `course-${course.selfLearnCourseId}`,
    searchTextFieldName: 'title',
  });

  const TITLE = `${`${form.bannerId ? '編輯' : '新增'}`}彈出海報`;

  const BreadcrumbsOptions = [
    { title: '彈出海報', path: '/popup-banner' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs
            title={`${form.bannerId ? '編輯' : '新增'}彈出海報`}
            breadcrumbItems={BreadcrumbsOptions}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={INFOS_SCHEMA}
                        onSubmit={onSubmit}
                        enableReinitialize
                      >
                        {({ values, errors, setFieldValue }) => (
                          <Form className="form-horizontal">
                            <div>
                              <Row>
                                <Col md={12}>
                                  <InputField
                                    name={'title'}
                                    label={'彈出海報名稱'}
                                    placeholder={'彈出海報名稱'}
                                    type={'text'}
                                  />
                                </Col>

                                <Col md={6}>
                                  <SelectField
                                    name="isPublishNow"
                                    label="發佈時間"
                                    options={[
                                      { label: '立即發佈', value: 'true' },
                                      { label: '預約發佈', value: 'false' },
                                    ]}
                                  />
                                </Col>
                                {values.isPublishNow !== 'true' ? (
                                  <Col md={6}>
                                    <DateField
                                      name="publishedDate"
                                      label="發佈時間"
                                      placeholder="發佈時間"
                                      enableTimeInCAL
                                    />
                                  </Col>
                                ) : null}
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <SelectField
                                    name={'bannerType'}
                                    label={'彈出海報類型'}
                                    placeholder={'彈出海報類型'}
                                    type={'text'}
                                    options={BANNER_TYPE_OPTIONS}
                                  />
                                </Col>

                                {values.bannerType === 'Course' ? (
                                  <Col md={6}>
                                    <AutoCompleteField
                                      key="event"
                                      name="courseId"
                                      label="活動名稱"
                                      placeholder="活動名稱"
                                      inputValue={event}
                                      onInputChange={setEventText}
                                      options={eventList}
                                      onMenuScrollToBottom={
                                        onMenuScrollToBottom
                                      }
                                    />
                                  </Col>
                                ) : null}

                                {values.bannerType === 'SelfLearnCourse' ? (
                                  <Col md={6}>
                                    <AutoCompleteField
                                      key="selfLearnCourse"
                                      name="selfLearnCourseId"
                                      label="課程名稱"
                                      placeholder="課程名稱"
                                      inputValue={selfLearnCourse}
                                      onInputChange={setSelfLearnCourseText}
                                      options={selfLearnList}
                                      onMenuScrollToBottom={
                                        onMenuSelfLearnScrollToBottom
                                      }
                                    />
                                  </Col>
                                ) : null}

                                <Col md={12}>
                                  <ImageField
                                    name={'image'}
                                    label={'圖片 (請上傳寬高比率為1:1的圖片)'}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {values.bannerId ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible?.(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Dialog
        visible={deleteVisible!}
        title={'刪除彈出海報'}
        onClose={() => setDeleteVisible?.(false)}
        loading={loading}
        onConfirm={() => onDeleteData()}
      >
        <p>{`確定要刪除此彈出海報 (編號:${form.bannerId}) 嗎?`}</p>
      </Dialog>
    </>
  );
};
