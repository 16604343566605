import { AdminProfileForm } from 'app/components/Admin/AdminProfileForm';
import Dialog from 'app/components/Dialog';
import { UserAvatar } from 'app/components/User';
import { AdminRolesMap } from 'app/models';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { RootState } from 'store';

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);

  const { profile } = useSelector((rootState: RootState) => rootState.auth);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect d-flex flex-row align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <UserAvatar
            avatar={profile?.avatar}
            alt={profile?.displayName}
            size="xs"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {profile?.displayName || 'Admin'}
          </span>
          {profile?.role ? (
            <Badge color="info" className="mt-1">
              {AdminRolesMap[profile.role]}
            </Badge>
          ) : null}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <button className="dropdown-item" onClick={() => setVisible(true)}>
            <i className="bx bx-id-card font-size-16 align-middle me-1 text-muted" />
            更改資料
          </button>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Dialog
        visible={visible}
        title="更改資料"
        onClose={() => setVisible(false)}
      >
        <AdminProfileForm onClose={() => setVisible(false)} />
      </Dialog>
    </React.Fragment>
  );
};

export default ProfileMenu;
