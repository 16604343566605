export const BANNER_ALL_STATUS_OPTIONS = [
  { label: '所有', value: '' },
  { label: '已發佈', value: 'Published' },
  { label: '未發佈', value: 'Unpublished' },
];

export const BANNER_TYPE_OPTIONS = [
  { label: '首頁橫額', value: 'HomePage' },
  { label: '課程橫額', value: 'SelfLearnCourse' },
  { label: '活動橫額', value: 'Course' },
  { label: '線上輔導橫額', value: 'ToConsultation' },
  // { label: '線上輔導橫額', value: 'ConsultationBooking' },
];
