import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TestData } from 'app/models';
import Tooltip from 'rc-tooltip';

const CourseTestListColumns = () => [
  {
    text: '課程測試編號',
    dataField: 'selfLearnTestId',
    formatter: (test: TestData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{test.selfLearnTestId}</div>
        </h5>
      </>
    ),
  },
  {
    text: '課程測試標題',
    dataField: 'description',
    formatter: (test: TestData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div
            className="text-dark"
            style={{
              maxWidth: 300,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {test.description ? test.description : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (test: TestData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(test.createdAt).format('YYYY-MM-DD')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (test: TestData) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <Tooltip
            placement="top"
            overlayStyle={{ padding: '5px 0 5px 0' }}
            overlay={<div className="px-3 py-1 fs-6">複製課程測試</div>}
            overlayInnerStyle={{ borderRadius: '4px' }}
          >
            <Link
              to={`/course-tests/new?testId=${test.selfLearnTestId}`}
              data-for={'copy' + test.selfLearnTestId}
              data-tip
            >
              <i className="mdi mdi-content-copy" />
            </Link>
          </Tooltip>
        </li>
        <li className="list-inline-item px-2">
          <Tooltip
            placement="top"
            overlayStyle={{ padding: '5px 0 5px 0' }}
            overlay={<div className="px-3 py-1 fs-6">編輯課程測試</div>}
            overlayInnerStyle={{ borderRadius: '4px' }}
          >
            <Link
              to={`/course-tests/edit/${test.selfLearnTestId}`}
              data-for={'edit' + test.selfLearnTestId}
              data-tip
            >
              <i className="bx bx-edit-alt" />
            </Link>
          </Tooltip>
        </li>
      </ul>
    ),
  },
];

export default CourseTestListColumns;
