/**
 *
 * CourseTestList
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Table, Pagination } from 'app/components/Table';
import { useTableData } from 'app/hooks';

import Breadcrumbs from 'app/components/Common/Breadcrumb';
import TableCount from 'app/components/Common/TableCount';
import { APP_NAME } from 'app/config';

// service

import CourseTestListColumns from './CourseTestListColumns';
import { getTestList, GetTestListParams } from 'app/services/CourseService';
import { TestData } from 'app/models';

export const CourseTestListPage = () => {
  const {
    dataList: courseTestList,
    count,
    page,
  } = useTableData<GetTestListParams, TestData>({
    call: getTestList,
    filterVariableArray: [],
  });

  return (
    <div className="page-content">
      <Helmet>
        <title>課程測試 | {APP_NAME}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs title="課程測試" breadcrumbItem="課程測試列表" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <>
                  <Row className="mb-2">
                    <Col sm={12}>
                      <div className="d-flex flex-row-reverse">
                        <a
                          href="/course-tests/new"
                          className="btn btn-primary"
                          role="button"
                          aria-pressed="true"
                        >
                          <i className="mdi mdi-plus" />
                          {` ${'新增課程測試'}`}
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col
                      sm={12}
                      className="d-flex align-self-end justify-content-end"
                    >
                      <TableCount count={count} title="課程測試數" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <Table
                          columns={CourseTestListColumns()}
                          keyField="id"
                          data={courseTestList || []}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                      <Pagination count={count} current={page} />
                    </Col>
                  </Row>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
